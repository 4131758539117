export interface Country {
    code?: string;
    currencyCode?: string;
}

export interface Site {
    id?: number;
    code?: string;
    label?: string;
    backofficeLabel?: string;
    /** @default false */
    hasMandatoryLogin?: boolean;
    legacyCurrencyCookie?: number;
    defaultLocale?: SiteLocale;
    availableLocales?: SiteLocale[];
    /** @default true */
    currencyWithSpace?: boolean;
    /** @default "right" */
    currencyPosition?: string;
    /** @default false */
    useGlobale?: boolean;
    defaultCurrency?: {
        id?: number;
        code?: string;
        label?: string;
        legacyId?: number;
    };
    availableCurrencies?: {
        id?: number;
        code?: string;
        label?: string;
        legacyId?: number;
    }[];
    shippingCountries?: {
        id?: number;
        code?: string;
        label?: string;
        currencyCode?: string;
        outputCurrencyCode?: string | null;
    }[];
    currentWorkspace?: {
        id?: number;
        code?: string;
        name?: string;
    };
    defaultCountryCode?: string;
    /** @default true */
    hasGiftEcardOtherAmountOption?: boolean;
}

export interface SiteLocale {
    id?: number;
    code?: string;
}

export interface SitesList {
    sites?: Site[];
    countries?: {
        label?: string;
        currencyCode?: string;
        outputCurrencyCode?: string | null;
        currencyLabel?: string;
        freeShippingThreshold?: number;
    }[];
    parameters?: Record<string, string>;
    globalECountries?: string[];
    mappingCountrySite?: object;
}

export interface SiteDetails {
    /** @default false */
    hasMandatoryLogin?: boolean;
    header?: MenuItemV2[];
    prefooter?: MenuItem[];
    footer?: MenuItem[];
    sales?: Sale[];
}

export interface MandatoryLoginDetails {
    /** @default "image" */
    mediaType?: 'image' | 'video';
    mediaUrl?: string;
    content?: string;
}

export interface Sale {
    hash?: string;
    name?: string;
    path?: string;
    enabled?: boolean;
    menu?: MenuItem;
    products?: Product[];
}

export interface SaleV2 {
    hash?: string;
    name?: string;
    path?: string;
    enabled?: boolean;
    menu?: MenuItemV2;
    products?: Product[];
}

export interface MenuItem {
    uuid?: string;
    level?: number;
    /** @default false */
    selected?: boolean;
    label?: string;
    seeAllLabel?: string;
    description?: string;
    labelColor?: string;
    labelHoverColor?: string;
    labelIsBold?: boolean;
    labelIsItalic?: boolean;
    customStyle?: string;
    link?: string;
    /** @default false */
    hasProducts?: boolean;
    target?: string;
    divider?: boolean;
    navigation?: boolean;
    mediaUrl?: string;
    mediaLabel?: string;
    mediaLabelColor?: string;
    blockType?: string;
    menuItemDropDowns?: MenuItemDropDown[];
    enabled?: boolean;
    type?: string;
    subCategoryIds?: string[];
    /** @default "" */
    mobileMediaUrl?: string;
    /** @default false */
    mobileFullWidth?: boolean;
    showAll?: boolean;
    /** @default false */
    animation?: boolean;
    logoType?: string;
    children?: MenuItem[];
}

export interface MenuItemV2 {
    uuid?: string;
    level?: number;
    /** @default false */
    selected?: boolean;
    label?: string;
    seeAllLabel?: string;
    description?: string;
    labelColor?: string;
    labelHoverColor?: string;
    labelIsBold?: boolean;
    labelIsItalic?: boolean;
    customStyle?: string;
    link?: string | null;
    /** @default false */
    hasProducts?: boolean;
    target?: string;
    divider?: boolean;
    navigation?: boolean;
    mediaUrl?: string;
    mediaLabel?: string;
    mediaLabelColor?: string;
    blockType?: string;
    menuItemDropDowns?: MenuItemDropDown[];
    enabled?: boolean;
    type?: string;
    subCategoryIds?: string[];
    /** @default "" */
    mobileMediaUrl?: string;
    /** @default false */
    mobileFullWidth?: boolean;
    showAll?: boolean;
    /** @default false */
    animation?: boolean;
    logoType?: string;
    children?: MenuItem[];
}

export interface MenuItemDropDown {
    id?: number;
    media?: Media;
    product?: Variant;
    catalogVariant?: Variant;
    pageId?: number;
    staticPage?: string;
    position?: number;
    cardBlockType?: 'image' | 'video' | 'product';
    imageUrl?: string;
    videoUrl?: string;
    preview?: string;
    loop?: boolean;
    hash?: string;
    autoplay?: boolean;
    link?: string;
    text?: string;
    textColor?: ColorParameter;
    textColorOverride?: string;
    linkType?: string;
    target?: string;
    ctaText?: string;
    ctaColor?: ColorParameter;
    ctaColorOverride?: string;
    /** @default false */
    cardBlockOuter?: boolean;
    mediaTransformations?: string[];
}

export interface Media {
    id?: number;
    CdnPublicId?: string;
    cdnSecureUrl?: string;
    cdnThumbnails?: any[];
    video?: boolean;
    previewUrl?: string;
    name?: string;
}

export interface Variant {
    id?: number;
    label?: string;
    price?: number;
    crossedOutPrice?: number;
    displayCrossedOutPrice?: string;
    displayPrice?: string;
}

export interface ColorParameter {
    id?: number;
    value?: string;
}

export interface Order {
    number?: string;
    /**
     * uuid from id
     * @format uuid
     */
    uuid?: string;
    userId?: string;
    status?: OrderStatus;
    /**
     * Creation date
     * @format date-time
     */
    createdAt?: string;
    /**
     * Last update date
     * @format date-time
     */
    updatedAt?: string;
    /**
     * Expiration date for a cart
     * @format date-time
     */
    expireAt?: string;
    /** @format date-time */
    orderedAt?: string;
    /** @format date-time */
    cancelledAt?: string;
    invoiceLink?: string;
    /** Total number of products in the order */
    totalProductQuantity?: number;
    moreProductQuantity?: number;
    /** Number of products for last minute shopping */
    lastMinuteQuantity?: number;
    /** Number of products for simple delivery */
    simpleDeliveryQuantity?: number;
    /** Amount tax included for all the articles, in cents */
    subTotalPrice?: number;
    /** Amount tax included for all the articles, with currency */
    subTotalDisplayPrice?: string;
    /** Amount tax included for the shipping, in cents */
    shippingPrice?: number;
    /** Amount tax included for the shipping, with currency */
    shippingDisplayPrice?: string;
    /** Amount tax included of the discount, in cents */
    discountPrice?: number;
    /** Amount tax included of the discount, with currency */
    discountDisplayPrice?: string;
    /** Total vat, in cents */
    totalVat?: number;
    /** Total vat, with currency */
    totalVatDisplayed?: string;
    /** Total price tax included without shipping, in cents */
    totalPriceWithoutShipping?: number;
    /** Total price tax included without shipping, with currency */
    totalDisplayPriceWithoutShipping?: string;
    /** Total price tax included, in cents */
    totalPrice?: number;
    /** Total price tax included, with currency */
    totalDisplayPrice?: string;
    /** Total price tax included for customs, in cents */
    customsPrice?: number;
    /** Total price tax included for customs, with currency */
    customsDisplayPrice?: string;
    /** Amount sales tax, in cents */
    amountSalesTax?: number;
    /** Amount for customs */
    amountForCustoms?: number;
    /** Amount for customs, with currency */
    amountForCustomsDisplayPrice?: string;
    /** Amount sales tax, with currency */
    amountSalesTaxDisplay?: string;
    /** Amount duties and other fees, in cents */
    amountDutiesAndOtherFees?: number;
    /** Amount duties and other fees, with currency */
    amountDutiesAndOtherFeesDisplay?: string;
    /** Amount duties and other fees threshold, in cents */
    amountDutiesAndOtherFeesThreshold?: number | null;
    /** Amount duties and other fees threshold, with currency */
    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
    currencyCode?: string;
    /** Currency code used for Globale order */
    localCurrencyCode?: string;
    lastShippingProvider?: string;
    products?: OrderProduct[];
    packaging?: OrderProduct[];
    vouchers?: OrderVoucher[];
    userCredits?: OrderVoucher[];
    ecards?: OrderVoucher[];
    availableUserCredits?: OrderVoucher[];
    addresses?: {
        shipping?: Address;
        lastMinuteShipping?: Address;
        billing?: Address;
    };
    /** @default false */
    billingAddressSameAsShipping?: boolean;
    workflows?: {
        userAbortAvailable?: boolean;
    };
    promotionalCampaignHeaders?: string[];
    /** promotional campaigns applied to the cart. PromotionalCampaignHeaders not included */
    promotionalCampaigns?: {
        /** id of the promotional campaign */
        id?: number;
        uid?: string;
        /** Code of the promotional campaign */
        code?: string;
    }[];
    shippingTrackingCode?: string;
    shippingTrackingLink?: string;
    shippingTrackingStatus?: number;
    isReturnable?: boolean;
    /**
     * Should display or not prices on user account
     * @default true
     */
    displayPrices?: boolean;
    paymentMethod?: string;
    /** gift message add on an order at the checkout */
    giftMessage?: GiftMessage;
    shippingMode?: ShippingMode;
    lastMinuteShippingMode?: ShippingMode;
    slot?: Slot;
    /** @default true */
    needAddress?: boolean;
    /** @default true */
    enableGiftMessage?: boolean;
    externalId?: string;
    globalEId?: string;
    childOrderIds?: number[];
    multiproductUid?: string;
    orderReferenceBarcodePath?: string | null;
    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
    minorUnitsTotalPrice?: number;
    refund_hold_amount?: number;
    isPoshmarkResellable?: boolean | null;
}

export interface OrderStatus {
    current?: string;
    /** @format date-time */
    order?: string;
    /** @format date-time */
    processing?: string;
    /** @format date-time */
    shipping?: string;
    /** @format date-time */
    estimatedShipped?: string;
    /** @format date-time */
    shipped?: string;
    /** @format date-time */
    delivered?: string;
}

export interface OrderProduct {
    ean?: string;
    orderItemProductId?: string;
    /** Name of the model (localized),Name of the model (localized) */
    label?: string;
    /** Size value (localized) */
    sizeLabel?: string;
    /** Color of the variant (localized) */
    colorLabel?: string;
    /** Cloudinary url for the main media */
    imageUrl?: string;
    /** Url for the product */
    productUrl?: string;
    productProperty?: ProductProperty;
    ecardProperty?: EcardProperty;
    /**
     * Is this article of packaging type
     * @default false
     */
    packaging?: boolean;
    /** Number of items (simple delivery + last minute shopping),Number of items (simple delivery + last minute shopping) */
    quantity?: number;
    /** Number of items for last minute shopping */
    lastMinuteQuantity?: number;
    /** Number of items for simple delivery */
    simpleDeliveryQuantity?: number;
    /**
     * Number of items from retail
     * @default 0
     */
    retailQuantity?: number;
    /** Unit price of the article, ready to be displayed, with currency */
    unitDisplayPrice?: string;
    /** Unit price of the article, in cents */
    unitPrice?: number;
    /** Unit catalog price of the article, ready to be displayed, with currency */
    catalogDisplayPrice?: string;
    /** Is the product in an archive sale ? */
    archives?: boolean;
    /** Crossed out price of the article, ready to be displayed, with currency */
    crossedOutDisplayPrice?: string | null;
    /** Crossed out price of the article, in cents */
    crossedOutPrice?: number | null;
    /** Total price of the articles, ready to be displayed, with currency */
    totalDisplayPrice?: string;
    /** Total price of the articles, in cents */
    totalPrice?: number;
    /** Total crossed out price of the articles, ready to be displayed, with currency */
    totalCrossedOutDisplayPrice?: string | null;
    /** Total Crossed out price of the articles, in cents */
    totalCrossedOutPrice?: number | null;
    /**
     * Indicates if customization is possible on this item
     * @default false
     */
    isCustomizable?: boolean;
    /** A customisation applied on an item */
    customisation?: OrderItemCustomisation | null;
    /**
     * Packaging options available for this product
     * @default []
     */
    packagingOptions?: PackagingOption[];
    /** @default false */
    gift?: boolean;
    returnableQuantity?: number | null;
    /**
     * list of product included in the multiproduct
     * @default []
     */
    multiProducts?: OrderMultiProduct[];
    /** Size value (localized) */
    size?: string;
    /** Color of the variant (localized) */
    color?: string;
    hsCode?: string;
    midCode?: string;
    gender?: string;
    /** eg. silk, wool, cotton, etc */
    composition?: string;
    /** eg. knit */
    weaving?: string;
    isPreferentialOrigin?: boolean;
    /** eg. china, portugal, italy, etc */
    origin?: string;
    rsePictos?: string[];
    /** VAT rate (eg. 2000 for 20%) */
    vatRate?: number;
    /** Unit price of the article taxes included, in cents */
    unitPriceTaxesIncluded?: number;
    /** Unit price of the article taxes excluded, in cents */
    unitPriceTaxesExcluded?: number;
    /** Unit price x quantity taxes included, in cents */
    totalAmountTaxesIncluded?: number;
    /** Unit price x quantity taxes excluded, in cents */
    totalAmountTaxesExcluded?: number;
    /** Eco-participation price x quantity for this product */
    ecoParticipationPrice?: number;
    [key: string]: any;
}

export interface OrderMultiProduct {
    ean?: string;
    orderItemProductId?: string;
    /** Name of the model (localized) */
    label?: string;
    /** Size value (localized) */
    sizeLabel?: string;
    /** Color of the variant (localized) */
    colorLabel?: string;
    /** Cloudinary url for the main media */
    imageUrl?: string;
}

export type ProductProperty = {
    productId?: number;
    hash?: string | null;
    name?: string | null;
    price?: number;
    crossedOutPrice?: number | null;
    displayPrice?: string;
    displayCrossedOutPrice?: string | null;
    /** @example "standard" */
    vatType?: string | null;
    link?: string | null;
    selectedVariant?: VariantProperty;
    variants?: VariantProperty[];
    /** A customisation option defined in the BO for a product */
    customisation?: Customisation;
    /** product subcategory */
    subcategory?: string | null;
    customisationMediaUrl?: string | null;
    hsCode?: Record<string, any>;
    taxCode?: string | null;
    childTaxCode?: string | null;
    madeInCode?: string | null;
    midCode?: string | null;
    weaving?: string | null;
    composition?: string | null;
    gender?: string | null;
    /** @format uuid */
    genderUuid?: string | null;
    csr?: string | null;
    csrExtended?: CorporateSocialResponsibility[] | null;
    dangerous?: boolean | null;
    dangerousClassification?: number | null;
    dangerousOnu?: string | null;
    dangerousId8000?: boolean | null;
    weight?: number | null;
    description?: string | null;
    /**
     * product category uuid
     * @default ""
     */
    categoryUuid?: string | null;
    /**
     * product category label
     * @default ""
     */
    categoryLabel?: string | null;
    /**
     * Size value
     * @default ""
     */
    sizeLabel?: string;
    /**
     * Color of the variant
     * @default ""
     */
    colorLabel?: string;
    /**
     * Cloudinary url for the main media
     * @default ""
     */
    imageUrl?: string;
    /** @default "" */
    code?: string;
    /** product brand */
    brand?: string | null;
    /** product brand in PIM (ie. petitsezane, octobre or sezane) */
    productBrand?: 'petitsezane' | 'octobre' | 'sezane' | null;
};

export type MultiProductProperty = {
    productId?: number;
    hash?: string | null;
    name?: string | null;
    price?: number;
    crossedOutPrice?: number | null;
    displayPrice?: string;
    displayCrossedOutPrice?: string | null;
    /** @example "standard" */
    vatType?: string | null;
    path?: string | null;
    products?: ProductProperty[];
    /** product subcategory */
    subcategory?: string | null;
    description?: string | null;
    /**
     * product category uuid
     * @default ""
     */
    categoryUuid?: string | null;
    /**
     * product category label
     * @default ""
     */
    categoryLabel?: string | null;
};

export type VariantProperty = {
    variantId?: number;
    storeVariantId?: number | null;
    hash?: string | null;
    selected?: boolean;
    /** @default true */
    available?: boolean;
    /** @default false */
    availableSoon?: boolean;
    name?: string | null;
    code?: string;
    link?: string | null;
    imageUrl?: string;
    photos?: ProductVariantPhoto[] | null;
    price?: number;
    crossedOutPrice?: number | null;
    displayPrice?: string;
    displayCrossedOutPrice?: string | null;
    selectedItem?: ItemProperty;
    items?: ItemProperty[];
    tracking?: TrackingCms;
    preorder?: boolean;
    preorderDate?: string | null;
    preorderText?: string | null;
    /** A customisation option defined in the BO for a product */
    customisation?: Customisation | null;
    /** @default false */
    isCrossSellPopin?: boolean;
    /** @default false */
    isCrossSellProductPopin?: boolean;
    /** UUID de la variant */
    uuid?: string | null;
    /** legacy model ID */
    legacyModelCode?: number | null;
    stock?: number | null;
};

export type ItemProperty = {
    uuid?: string;
    itemId?: number;
    hash?: string | null;
    /** @default true */
    available?: boolean;
    scale?: string | null;
    size?: string;
    sizeUuid?: string;
    link?: string;
    ean?: string;
    /** @default false */
    inOtherBasket?: boolean;
    /** @default false */
    lastMinuteShoppingAvailable?: boolean;
    /** @default null */
    lastCallThreshold?: number | null;
    /** @default false */
    isLastCall?: boolean;
    stock?: number | null;
};

export type EcardProperty = {
    ean?: string | null;
    category?: string | null;
    productType?: string | null;
    name?: string | null;
    slug?: string | null;
    color?: string | null;
    image?: string | null;
    priceTaxIncluded?: number;
    priceTaxExcluded?: number | null;
    message?: string | null;
    senderLastname?: string;
    senderFirstname?: string;
    receiverFirstname?: string;
    receiverLastname?: string;
    receiverEmail?: string;
    /** @format date-time */
    sendAt?: string;
};

export interface OrderVoucher {
    id?: number;
    uid?: string | null;
    code?: string;
    /** Price in cents */
    price?: number;
    /** Price to display, with currency */
    displayPrice?: string;
}

export interface ShippingDetails {
    zpl_label_path?: string;
    cn23_label_path?: string;
    tracking_number?: string;
    identification_number?: string;
    relay_point?: RelayPoint[];
}

export interface RelayPoint {
    id?: string;
    picture_url?: string;
    name?: string;
    opening_hours?: {
        monday?: RelayPointOpeningPeriod[];
        tuesday?: RelayPointOpeningPeriod[];
        wednesday?: RelayPointOpeningPeriod[];
        thursday?: RelayPointOpeningPeriod[];
        friday?: RelayPointOpeningPeriod[];
        saturday?: RelayPointOpeningPeriod[];
        sunday?: RelayPointOpeningPeriod[];
    };
    customerAddress?: Address;
    relayAddress?: Address;
}

export interface RelayPointOpeningPeriod {
    begin: string;
    end: string;
}

export interface Address {
    id?: number;
    uid?: string;
    name?: string;
    gender?: number;
    firstName?: string;
    lastName?: string;
    otherLastName?: string;
    fullName?: string;
    company?: string;
    phoneNumber?: string;
    city?: string;
    postcode?: string;
    address: string;
    addressMore?: string;
    state?: string;
    country: string;
    countryCode?: string;
    latitude?: number;
    longitude?: number;
    isDefaultShipping?: boolean;
    isDefaultBilling?: boolean;
    relayId?: string;
    /** full formatted address. eg. 115 rue du Bac, 75007 Paris, France, +33642424242 */
    formattedAddress?: string;
    civility?: number;
    [key: string]: any;
}

export interface CrossSell {
    crossSellProducts?: Product[];
}

export interface OrderError {
    message?: string;
}

/** gift message add on an order at the checkout */
export type GiftMessage = {
    /** gift message title */
    title?: string | null;
    /** gift message body */
    message?: string | null;
};

/** Promotional campaign */
export interface PromotionalCampaign {
    id?: number;
    uid?: string;
    title?: string;
    name?: string;
    description?: string;
    validationText?: string;
    cancelationText?: string;
    products?: ProductProperty[];
    /** If the user has a choice to make. For example, if he has to choose between several products */
    withChoice?: boolean;
    /**
     * True if only for display. False if an action to add is expected
     * @default false
     */
    displayOnly?: boolean;
}

/** A customisation applied on an item */
export type OrderItemCustomisation = {
    rows?: string[] | null;
    color?: string | null;
    position?: string | null;
    customisationMediaUrl?: string | null;
    /** A customisation option defined in the BO for a product */
    service?: Customisation | null;
    type?: string | null;
    format?: string | null;
};

/** Representing Problem Details in HTTP APIs,Representing Problem Details in HTTP APIs,Representing Problem Details in HTTP APIs */
export interface Rfc7807 {
    type?: string;
    title?: string;
    status?: number;
    detail?: string;
    /**
     * @minItems 1,1,1
     * @uniqueItems true,true,true
     */
    'invalid-params'?: any[];
    [key: string]: any;
}

export interface Invoice {
    id?: number;
    displayTaxesExcludedPrices?: boolean;
    firstname?: string;
    lastname?: string;
    email?: string;
    /** eg. WEB-FR, WEB-UK, etc */
    orderType?: string;
    number?: string;
    status?: string;
    /** true if at least one product is preferential origin */
    isPreferentialOrigin?: boolean;
    /** @format date-time */
    createdAt?: string;
    incoterm?: string;
    paymentMethod?: string;
    shippingMode?: string;
    shippingTrackingCode?: string;
    currencyCode?: string;
    /** Total amount tax included, in cents */
    totalAmountTaxesIncluded?: number;
    /** Total amount tax excluded, in cents */
    totalAmountTaxesExcluded?: number;
    /** Total amount for customs, in cents */
    totalAmountForCustoms?: number;
    /** Total vat amount, in cents */
    vatAmount?: number;
    /** Total sales tax amount, in cents */
    salesTaxAmount?: number;
    /** Amount duties and other fees, in cents */
    amountDutiesAndOtherFees?: number;
    /** Discount included of the discount, in cents */
    discountAmountTaxesIncluded?: number;
    /** Sub total Amount tax included for all the articles, in cents */
    subTotalAmountTaxesIncluded?: number;
    /** Sub total Amount tax excluded for all the articles, in cents */
    subTotalAmountTaxesExcluded?: number;
    /** Shipping amount taxes included, in cents */
    shippingAmountTaxesIncluded?: number;
    /** Shipping amount taxes excluded, in cents */
    shippingAmountTaxesExcluded?: number;
    /** Shipping amount Without customs taxes, in cents */
    shippingAmountWithoutCustomsTaxes?: number;
    shippingAddress?: Address;
    billingAddress?: Address;
    products?: OrderProduct[];
    taxDetails?: TaxDetail[];
}

export interface TaxDetail {
    label?: string;
    rate?: number;
    totalTaxAmount?: number;
    totalAmountTaxesExcluded?: number;
}

export interface CustomSticker {
    gender?: number;
    firstname?: string;
    countryCode?: string;
    messages?: {
        title?: string;
        text?: string;
    }[];
}

export interface WorkspaceCreation {
    label: string;
    sites: number[];
    /** @format date-time */
    beginOn: string;
    /** @format date-time */
    finishOn: string;
}

export interface PageVersionsDuplication {
    sites: number[];
    pageVersions: number[];
}

export interface CategoriesDuplication {
    sites: number[];
    categories: number[];
}

export interface MediaUpload {
    medias: UploadedMedia[];
    linkProducts: boolean;
    hardVisual?: boolean;
    newsVisual?: boolean;
    moodVisual?: boolean;
    importedMediasActive: boolean;
    oldMediasActive: boolean;
    workspaceId: number;
    modelTags?: any[];
    variantTags?: any[];
}

export interface UploadedMedia {
    publicId: string;
    filename?: string;
    tags: string[];
    secureUrl: string;
    video: boolean;
    previewUrl: string;
    width?: number;
    height?: number;
}

export interface MediaPage {
    id?: number;
    pageType?: string;
    linkId?: number;
    nbUsed?: number;
    labels?: Record<string, string>;
}

export type RelatedRoutes = Record<
    string,
    {
        site?: string;
        route?: string;
    }
>;

export interface ProductRedirect {
    path?: string;
}

export interface ProductsAndFilters {
    products?: Product[];
    filters?: MenuFilters;
}

export interface Product {
    hash?: string;
    context?: StoreContext;
    brandCode?: string;
    productBrand?: string;
    isLeathercraft?: boolean;
    /** @default false */
    displaySubCategoryProducts?: boolean;
    /** @default false */
    exceptionSubCatLeathercraft?: boolean;
    isShoes?: boolean;
    isCrossSellPopin?: boolean;
    isCrossSellProductPopin?: boolean;
    showSizeAdvice?: boolean;
    site?: string;
    category?: string;
    nextProduct?: string;
    previousProduct?: string;
    type?: string;
    label?: string;
    link?: string;
    routes?: RelatedRoutes;
    variantsPictureCount?: number;
    selectedVariant?: ProductVariant;
    variants?: ProductVariant[];
    crossSellProducts?: Product[];
    crossSellPopin?: Product[];
    crossSellProductPopin?: {
        title?: string;
        description?: string;
        products?: Product[];
    };
    leatherCareVariants?: ProductVariant[];
    alternateUrls?: AlternateUrl[];
    sizeGuideLink?: string | null;
    imageUrl?: string | null;
    /** @default false */
    mediaExistsInVariant?: boolean;
    typologyUuid?: string | null;
}

export interface MultiProduct {
    uuid?: string;
    hash?: string;
    context?: StoreContext;
    site?: string;
    label?: string;
    description?: string;
    technicalDescription?: string[];
    link?: string;
    routeName?: string;
    routes?: RelatedRoutes;
    photos?: ProductVariantPhoto[];
    products?: Product[];
    seo?: SeoOverride;
    /** @default false */
    available?: boolean;
    price?: number;
    displayPrice?: string;
    csr?: CorporateSocialResponsibility[];
    /** @default false */
    displaySubCategoryProducts?: boolean;
    multiProducts?: MultiProduct[];
}

export interface SeoOverride {
    path?: string;
    metaTitle?: string;
    metaDescription?: string;
    metaRobots?: string;
    metaCanonical?: string;
    ogTitle?: string;
    ogDescription?: string;
    edito?: string;
}

export interface ProductVariant {
    id?: number;
    hash?: string;
    context?: StoreContext;
    label?: string;
    code?: string;
    tracking?: Tracking;
    saleName?: string | null;
    leathercraftData?: LeathercraftData;
    productLabel?: string;
    link?: string;
    routes?: RelatedRoutes;
    price?: number;
    displayPrice?: string;
    discountedPrice?: number | null;
    colorLabel?: string;
    description?: string;
    technicalDescription?: string[];
    preOrderDescription?: string;
    seo?: SeoOverride;
    modelSizing?: string;
    sizeAdvice?: string;
    sizingTips?: string | null;
    models?: ProductVariantPhotoModel[];
    sizeUnit?: string;
    care?: string;
    genericComposition?: string;
    displayComposition?: string;
    madeIn?: string;
    manufacturingCountry?: string;
    additionalInformation?: string;
    moreInformation?: string;
    selectedItem?: ProductVariantItem;
    items?: ProductVariantItem[];
    previewPhotoIndex?: number;
    /** @default 0 */
    photoIndex?: number;
    photos?: ProductVariantPhoto[];
    isCrossSellPopin?: boolean;
    isCrossSellProductPopin?: boolean;
    crossSellProducts?: string[];
    available?: boolean;
    availableSoon?: boolean;
    preorder?: boolean;
    preorderDate?: string;
    preorderText?: string;
    discounted?: boolean;
    crossedOutPrice?: number;
    displayCrossedOutPrice?: string;
    marking?: string[];
    possibleTransitions?: string[];
    catalogProductId?: number;
    catalogVariantId?: number;
    breadcrumb?: Breadcrumb;
    position?: number;
    csr?: CorporateSocialResponsibility[];
    sizingAdvice?: string;
    /** A customisation option defined in the BO for a product */
    customisation?: Customisation | null;
    workshop?: string | null;
    additionalDescription?: string | null;
    mediaVariants?: string[];
    stock?: number | null;
    /** @default 0 */
    includedRecycledMaterials?: number;
    /** @default "not_recyclable" */
    recyclability?: string;
    spinningCountry?: string;
    weavingCountry?: string;
    dyeingCountry?: string;
    quiltingCountry?: string;
    finishCountry?: string;
    assemblyCountry?: string;
    /** @default false */
    rareEarthsPresence?: boolean;
    /** @default false */
    plasticMicrofiberReject?: boolean;
    /** @default false */
    compostable?: boolean;
    /** @default false */
    reusable?: boolean;
    crossSellOtherSizes?: Product[];
    ecoParticipation?: number;
    availableSizeUuids?: string[];
    parentColorUuid?: string | null;
    typologyUuid?: string | null;
    ageUuid?: string | null;
}

/** A customisation option defined in the BO for a product */
export type Customisation = {
    name?: string | null;
    /** @default true */
    optional?: boolean | null;
    description?: string | null;
    price?: string | null;
    totalPrice?: string | null;
    authorizedCharacters?: string | null;
    authorizedCharactersPattern?: string | null;
    whitelistedCharacters?: string | null;
    legacyId?: number | null;
    fullValue?: string | null;
    /** Lines of text for this customisation */
    rows?: {
        /** Maximum number of characters allowed for this line */
        limit?: number | null;
        /** Value provided for this line by the user */
        value?: string | null;
    }[];
    colors?: CustomisationColor[] | null;
    positions?: string[] | null;
    positionsV2?:
        | {
              label?: string;
              value?: string;
              top?: string;
              right?: string;
              bottom?: string;
              left?: string;
          }[]
        | null;
    symbols?: string[] | null;
    customisationMediaUrl?: string | null;
    type?: string | null;
    format?: string | null;
};

export interface CustomisationColor {
    label?: string | null;
    value?: string | null;
}

export interface PackagingOption {
    /** Label for the packaging option */
    title?: string;
    /** Corresponding reference for this option in the legacy */
    packagingReference?: string;
    /** Is this option selected or not */
    selected?: boolean;
    /** Legacy index for mapping (to remove with legacy) */
    legacyOptions?: LegacyOptions;
}

/** Legacy index for mapping (to remove with legacy) */
export type LegacyOptions = {
    opecode?: number | null;
    packagingLegacyId?: number | null;
    packagingItemLegacyId?: number | null;
};

export interface Tracking {
    legacyId?: string;
    code?: string;
    category?: string;
    categoryReference?: string;
    name?: string;
    /** @default "product_group" */
    type?: string;
    currency?: string;
    ean?: string;
    price?: number;
    variantColor?: string;
    variantParentColor?: string;
    /** @default "Sezane" */
    brand?: string;
    productUuid?: string;
    sizeUuid?: string;
    collection?: string;
    fsColor?: string;
}

export interface LeathercraftData {
    workshop?: string;
    studioEditorialText?: string;
    studioEditorialCTAText?: string;
    studioEditorialCTALink?: string;
    expertiseText?: string;
    expertiseVideo?: string;
    expertiseLeftVisual?: string;
    expertiseRightVisual?: string;
    secondaryTitle?: string;
    secondaryText?: string;
    secondaryVisual?: string;
    secondaryCTAText?: string;
    secondaryCTALink?: string;
}

export interface ProductVariantPhoto {
    mainUrl?: string;
    imageUrl?: string;
    zoomUrl?: string;
    swiperUrl?: string;
    variantUrl?: string;
    crossSellUrl?: string;
    mediaType?: string;
    models?: ProductVariantPhotoModel[];
    cdnPublicId?: string;
    originalPhotoId?: number;
    canRequireEdition?: boolean;
}

export interface ProductVariantItem {
    hash?: string;
    context?: StoreContext;
    label?: string;
    link?: string;
    routes?: RelatedRoutes;
    size?: string;
    /** @default false */
    available?: boolean;
    /** @default false */
    lastMinuteShoppingAvailable?: boolean;
    /** @default false */
    inOtherBasket?: boolean;
    /** @default null */
    lastCallThreshold?: number | null;
    /** @default false */
    isLastCall?: boolean;
    ean?: string;
    uuid?: string;
    stock?: number | null;
}

export interface ProductVariantPhotoModel {
    name?: string;
    height?: number;
    size?: string;
}

export interface StoreContext {
    /** Currency Code */
    currency?: string;
    locale?: string;
    /** brand code */
    brand?: string;
    /** site code */
    site?: string;
    /** workspace ID */
    workspace?: number;
    /** workspace Name */
    workspaceName?: string;
    /** Sale ID */
    sale?: number;
    /** Country Code */
    countryCode?: string;
    /** Region Code */
    regionCode?: string;
}

export interface SearchSuggestion {
    suffix?: string | null;
}

export interface Breadcrumb {
    elements?: BreadcrumbItem[];
}

export interface BreadcrumbItem {
    label?: string;
    link?: string;
    /** @default false */
    selected?: boolean;
}

export interface CorporateSocialResponsibility {
    key?: string;
    label?: string;
    description?: string | null;
    link?: string | null;
}

export interface VariantShopStock {
    thresholdLow?: number;
    thresholdHigh?: number;
    availableShops?: StockShop[];
    availableItems?: StockItem[];
    shopsStock?: Record<string, string>;
}

export interface StockShop {
    id?: number;
    label?: string;
}

export interface StockItem {
    id?: string;
    label?: string;
}

export interface PushProduct {
    id?: number;
    name?: string;
    text?: string;
    imageUrl?: string;
    products?: ProductProperty[];
}

export type StockByEanAndShop = {
    ean?: string;
    shops?: {
        /** @format uuid */
        uid?: string;
        name?: string;
        address?: PhysicalAddress;
        openHours?: OpenHours | null;
        stock?: number;
    }[];
}[];

export interface PhysicalAddress {
    name?: string | null;
    address?: string;
    addressMore?: string | null;
    postcode?: string;
    city?: string;
    state?: string | null;
    country?: string | null;
    countryCode?: string | null;
    latitude?: number | null;
    longitude?: number | null;
}

export type OpenHours = {
    day1?: ShopOpenHour[] | null;
    day2?: ShopOpenHour[] | null;
    day3?: ShopOpenHour[] | null;
    day4?: ShopOpenHour[] | null;
    day5?: ShopOpenHour[] | null;
    day6?: ShopOpenHour[] | null;
    day7?: ShopOpenHour[] | null;
};

export interface ShopOpenHour {
    id?: number;
    day: number;
    startTime: string;
    endTime: string;
}

export interface Page {
    id?: number;
    path?: string;
    title?: string;
    alternateTitle?: string;
    description?: string;
    titleMeta?: string;
    descriptionMeta?: string;
    keywordsMeta?: string;
    mediaUrl?: string;
    saleName?: string | null;
    firstComposition?: Composition;
    compositions?: Composition[];
    /** @default false */
    hasMenu?: boolean;
    /** @default false */
    hasSubMenu?: boolean;
    /** @default false */
    firstOutOfContainer?: boolean;
    /** @default "default" */
    kindKey?: 'default' | 'lookbook-scroll' | 'news';
    /** @default 0 */
    countNewsClickListComposition?: number;
    /** @default false */
    isHomepage?: boolean;
    rubric?: 'static' | 'merch' | 'news';
    labels?: string[];
    labelColor?: string | null;
    /** @default "auto" */
    menuLabelColor?: string;
    borderColor?: string;
    stickyBlock?: Block[];
    accessOptionsType?: string;
    accessOptionsMediaUrl?: string;
    accessOptionsCtaColor?: string;
    accessOptionsCtaText?: string;
    accessOptionsColor?: string;
    accessOptionsHtmlText?: string;
    pageId?: number;
    routes?: Record<
        string,
        {
            site?: string;
            route?: string;
        }
    >;
}

export interface PageNews {
    id?: number;
    title?: string;
    alternateTitle?: string;
    subTitle?: string;
    description?: string;
    mediaUrl?: string;
    /** @format date-time */
    date?: string;
    legends?: string[];
    route?: string;
}

export interface Composition {
    name?: string;
    margin?: string | null;
    /** @default false */
    titlePositionInner?: boolean;
    /** @default false */
    withTwoMargins?: boolean;
    backgroundColor?: string | null;
    /** @default false */
    borderColorEnabled?: boolean;
    borderColor?: string | null;
    /** @default true */
    hasContainer?: boolean;
    position?: number;
    blocks?: Block[];
    order?: number[];
}

export enum Block {
    Spacer = 'Spacer',
    Image = 'Image',
    Instagram = 'Instagram',
    Look = 'Look',
    MultiProductCMS = 'MultiProductCMS',
    ProductCMS = 'ProductCMS',
    Slider = 'Slider',
    Text = 'Text',
    Title = 'Title',
    Video = 'Video',
    Html = 'Html',
    OptinNewsletter = 'OptinNewsletter',
}

export interface Spacer {
    position?: number;
    hash?: string | null;
    /** @default "Spacer" */
    type: string;
}

export interface Image {
    position?: number;
    hash?: string | null;
    /** @default "Image" */
    type: string;
    /** @default "" */
    imageUrl?: string;
    /** @default "" */
    mobileImageUrl?: string;
    /** @default "" */
    imageAlternativeUrl?: string;
    /** @default "" */
    backgroundColor?: string;
    /** @default "" */
    text?: string;
    /** @default "" */
    title?: string;
    /** @default "" */
    subTitle?: string;
    /** @default "" */
    description?: string;
    /** @default "" */
    textTop?: string;
    /** @default "" */
    textBottom?: string;
    /** @default "" */
    textLeft?: string;
    /** @default "" */
    textRight?: string;
    labelColor?: string;
    /** @default "black" */
    textColor?: string;
    /** @default "" */
    legend?: string;
    legendColor?: string;
    /** @default "" */
    link?: string;
    /** @default false */
    cta?: boolean;
    /** @default "" */
    ctaText?: string;
    /** @default "black" */
    ctaColor?: string;
    /** @default "left" */
    ctaAlign?: string;
    /** @default "" */
    ctaTarget?: string;
    /** @default true */
    displayCtaOnMobile?: boolean;
    displayBoth?: boolean;
}

export interface Instagram {
    position?: number;
    hash?: string | null;
    /** @default "Instagram" */
    type: string;
    /** @default "" */
    link?: string;
    images?: string[];
}

export interface Look {
    position?: number;
    hash?: string | null;
    /** @default "Look" */
    type: string;
    /** @default "" */
    imageUrl?: string;
    /** @default "" */
    mobileImageUrl?: string;
    /** @default "" */
    imageUrlAlternative?: string;
    /** @default "" */
    textColor?: string;
    /** @default "" */
    videoUrl?: string;
    /** @default "" */
    mobileVideoUrl?: string;
    /** @default "image" */
    mediaType?: string;
    /** @default false */
    autoplay?: boolean;
    /** @default false */
    loop?: boolean;
    /** @default false */
    buttonPlay?: boolean;
    /** @default false */
    buttonSound?: boolean;
    /** @default "" */
    previewImageUrl?: string;
    /** @default "" */
    mobilePreviewImageUrl?: string;
    /** @default false */
    titlePositionInner?: boolean;
    items?: LookItemProperty[];
    /** @default false */
    productNameInner?: boolean;
}

export interface MultiProductCMS {
    position?: number;
    hash?: string | null;
    /** @default "MultiProductCMS" */
    type: string;
    /** @default "" */
    imageUrl?: string;
    /** @default "" */
    imageAlternativeUrl?: string;
    product?: MultiProductProperty;
    /** @default "" */
    videoUrl?: string;
    /** @default "" */
    link?: string;
    /** @default "image" */
    mediaType?: string;
    /** @default "" */
    textTop?: string;
    /** @default "" */
    textBottom?: string;
    /** @default "" */
    textLeft?: string;
    /** @default "" */
    textRight?: string;
    name?: string;
    /** @default false */
    autoplay?: boolean;
    /** @default false */
    loop?: boolean;
    /** @default false */
    buttonPlay?: boolean;
    /** @default false */
    buttonSound?: boolean;
    /** @default "" */
    previewImageUrl?: string;
    /** @default "" */
    textColor?: string;
    selectedVariants?: number[];
    /**
     * true if selectedVariants is set and all variants are available
     * @default true
     */
    available?: boolean;
}

export interface ProductCMS {
    position?: number;
    hash?: string | null;
    /** @default "ProductCMS" */
    type: string;
    /** @default "" */
    imageUrl?: string;
    /** @default "" */
    mobileImageUrl?: string;
    /** @default "" */
    imageAlternativeUrl?: string;
    product?: ProductProperty;
    /** @default "" */
    textTop?: string;
    /** @default "" */
    textBottom?: string;
    /** @default "" */
    textLeft?: string;
    /** @default "" */
    textRight?: string;
    /** @default "black" */
    textColor?: string;
    /** @default "" */
    videoUrl?: string;
    /** @default "" */
    mobileVideoUrl?: string;
    /** @default "image" */
    mediaType?: string;
    /** @default false */
    autoplay?: boolean;
    /** @default false */
    loop?: boolean;
    /** @default false */
    buttonPlay?: boolean;
    /** @default false */
    buttonSound?: boolean;
    /** @default "" */
    previewImageUrl?: string;
    /** @default "" */
    mobilePreviewImageUrl?: string;
    /** @default false */
    productNameInner?: boolean;
}

export interface Slider {
    position?: number;
    hash?: string | null;
    /** @default "Slider" */
    type: string;
    slides?: SliderProperty[];
}

export interface Text {
    position?: number;
    hash?: string | null;
    /** @default "Text" */
    type: string;
    /** @default "" */
    text?: string;
    /** @default "" */
    title?: string;
    /** @default "" */
    subTitle?: string;
    /** @default "" */
    description?: string;
    /** @default "black" */
    textColor?: string;
    /** @default "" */
    linkUrl?: string;
    /** @default "_self" */
    target?: string;
    /** @default false */
    shifted?: boolean;
}

export interface Video {
    position?: number;
    hash?: string | null;
    /** @default "Video" */
    type: string;
    /** @default "" */
    videoUrl?: string;
    /** @default "" */
    mobileVideoUrl?: string;
    /** @default false */
    autoplay?: boolean;
    /** @default false */
    loop?: boolean;
    /** @default false */
    buttonPlay?: boolean;
    /** @default false */
    buttonSound?: boolean;
    /** @default "" */
    previewImageUrl?: string;
    /** @default "" */
    mobilePreviewImageUrl?: string;
    /** @default "" */
    legend?: string;
    legendColor?: string;
    /** @default "white" */
    textColor?: string;
    labelColor?: string;
    /** @default "" */
    text?: string;
    /** @default "" */
    textTop?: string;
    /** @default "" */
    textBottom?: string;
    /** @default "" */
    textLeft?: string;
    /** @default "" */
    textRight?: string;
    /** @default "" */
    link?: string;
    /** @default false */
    cta?: boolean;
    /** @default "" */
    ctaText?: string;
    /** @default "" */
    ctaColor?: string;
    /** @default "left" */
    ctaAlign?: string;
    /** @default "_self" */
    target?: string;
    /** @default true */
    displayCtaOnMobile?: boolean;
}

export interface Html {
    position?: number;
    hash?: string | null;
    /** @default "Html" */
    type: string;
    /** @default "" */
    html?: string;
}

export interface OptinNewsletter {
    position?: number;
    hash?: string | null;
    /** @default "OptinNewsletter" */
    type: string;
    /** @default "" */
    html?: string;
    /** @default "black" */
    textColor?: string;
    /** @default "" */
    videoUrl?: string;
    /** @default "" */
    imageUrl?: string;
}

export interface TrackingCms {
    legacyId?: string;
    ean?: string;
    name?: string;
    price?: number;
    /** @default "Sezane" */
    brand?: string;
    category?: string;
    categoryReference?: string;
    variantColor?: string;
    variantParentColor?: string;
    quantity?: number;
    productUuid?: string;
    sizeUuid?: string;
    collection?: string;
    fsColor?: string;
}

export interface LookItemProperty {
    hash?: string | null;
    name?: string;
    price?: number;
    crossedOutPrice?: number;
    displayPrice?: string;
    displayCrossedOutPrice?: string;
    link?: string;
    selectedVariant?: VariantProperty;
    variants?: VariantProperty[];
    advices?: string;
    /** @default false */
    isProduct?: boolean;
    /** @default false */
    isInWorkspace?: boolean;
    /** @default false */
    otherVariantIsInWorkspace?: boolean;
    /** @default false */
    comingSoon?: boolean;
}

export interface SliderProperty {
    imageUrl?: string;
    imageUrlAlternative?: string;
    /** @default "image" */
    mediaType?: string;
    /** @default "black" */
    arrowColor?: string;
    items?: LookItemProperty[];
}

export interface SubMenu {
    label?: string;
    link?: string;
    menuItems?: MenuItem[];
    menuFilters?: MenuFilters;
}

export interface Category {
    /** @default "" */
    name?: string;
    /** @default "" */
    link?: string;
    /** @default false */
    selected?: boolean;
    /** @default 1 */
    level?: number;
    selectedChildren?: Category;
    childrens?: Category[];
}

export interface MenuFilters {
    sizes?: Size[];
    colors?: Color[];
    typologies?: Typology[];
}

export interface Size {
    uuids?: string[];
    /** @default "" */
    name?: string | null;
    /** @default false */
    selected?: boolean;
    /** @default 1 */
    priority?: number;
    /** @default "" */
    size_grid_name?: string;
    /** @default "" */
    size_grid_uuid?: string;
    universalSize?: string | null;
    frenchSize?: string | null;
    americanSize?: string | null;
    sizeParts?: SizePart[];
    [key: string]: any;
}

export interface Color {
    uuid?: string;
    /** @default "" */
    name?: string;
    /** @default false */
    selected?: boolean;
    /** @default 1 */
    priority?: number;
}

export interface Typology {
    uuid?: string;
    /** @default "" */
    name?: string;
    /** @default false */
    selected?: boolean;
    /** @default 1 */
    priority?: number;
}

export interface WaitingPage {
    header?: string;
    imageUrl?: string;
    labelCTA?: string;
    linkCTA?: string;
    reinsuranceBlocks?: Reinsurance[];
}

export interface Reinsurance {
    content?: string;
    link?: string;
}

export interface PageResponse {
    page?: Page;
    /** @default "DEFAULT" */
    mode?: 'DEFAULT' | 'SIZE' | 'COLUMN';
    /** @default false */
    redirectWaitingPage?: boolean;
    waitingPageSale?: string;
    filteredBlocks?: Block[];
    alternateUrls?: AlternateUrl[];
}

export interface PageResponseLight {
    data?: Block[];
}

export interface PageNewsResponse {
    pages?: PageNews[];
}

export interface Popin {
    id?: number;
    uid?: string;
    title?: string;
    content?: string;
    ctaText?: string;
    link?: string;
    /** @default "_self" */
    target?: string;
    format?: string;
    mediaUrl?: string;
    /** @default "image" */
    mediaType?: string;
    /** @default "white" */
    colorPrimary?: string;
    /** @default "black" */
    colorSecondary?: string;
    /** @default 0 */
    oncePerSession?: number;
    /** @default false */
    allPages?: boolean;
    pages?: string[];
    countries?: string[];
    regions?: PopinRegion[];
    /** @default "black" */
    closeCtaColor?: string;
    product?: ProductProperty;
    /** @default false */
    addToCart?: boolean;
}

export interface PopinRegion {
    countryCode?: string;
    regions?: string[];
}

export interface MobileContent {
    title?: string;
    subTitle?: string | null;
    /** @default false */
    largeBlock?: boolean;
    description?: string;
    ctaText?: string;
    ctaLink?: string;
    leftLabel?: string;
    rightLabel?: string;
    /** @default false */
    portrait?: boolean;
    /** @default 1 */
    position?: number;
    /** @default "" */
    mediaUrl?: string;
    type?: string;
    homepageType?: string;
    redirectionType?: string;
    merchType?: string;
    titleType?: string;
    /** @format date-time */
    displayedDate?: string;
    sale?: Sale;
    page?: Page;
    pageProducts?: Product[];
    product?: Product;
    blackBackground?: boolean;
    hiddenTitleArrow?: boolean;
    landingTitle?: string;
    landingDescription?: string;
    /** @default "" */
    landingMediaUrl?: string;
    slides?: MobileContentSlide[];
}

export interface MobileContentV2 {
    title?: string;
    subTitle?: string | null;
    /** @default false */
    largeBlock?: boolean;
    description?: string;
    ctaText?: string;
    ctaLink?: string;
    leftLabel?: string;
    rightLabel?: string;
    /** @default false */
    portrait?: boolean;
    /** @default 1 */
    position?: number;
    /** @default "" */
    mediaUrl?: string;
    type?: string;
    homepageType?: string;
    redirectionType?: string;
    merchType?: string;
    titleType?: string;
    /** @format date-time */
    displayedDate?: string;
    sale?: SaleV2;
    page?: Page;
    pageProducts?: Product[];
    product?: Product;
    blackBackground?: boolean;
    hiddenTitleArrow?: boolean;
    landingTitle?: string;
    landingDescription?: string;
    /** @default "" */
    landingMediaUrl?: string;
    slides?: MobileContentSlide[];
}

export interface MobileContentSlide {
    title?: string;
    subtitle?: string;
    /** @default "" */
    mediaUrl?: string;
    link?: string;
}

export interface PageRedirect {
    path?: string;
}

export interface FilterVariantBody {
    variantUuids?: string[];
    sizeUuids?: string[];
    colorUuids?: string[];
    typologyUuids?: string[];
}

export interface FilterVariantResponse {
    variants?: string[];
}

export interface RelayChoices {
    relays?: RelayPoint[];
}

export interface OrderDeliveryChoices {
    availableShippingModes?: ShippingMode[];
}

export interface OrderDeliveryGift {
    enabled?: boolean;
    title?: string;
    body?: string;
}

export interface OrderDeliveryRelay {
    searchPostcode?: string;
    id?: string;
}

export interface OrderDeliveryPayment {
    storedCard?: string;
    type?: string;
    holderName?: string;
    encryptedCardNumber?: string;
    encryptedExpiryMonth?: string;
    encryptedExpiryYear?: string;
    encryptedSecurityCode?: string;
    storeCard?: boolean;
}

export interface OrderDelivery {
    order?: Order;
    countryCode?: string;
    /** Shipping Mode ID */
    shippingModeId?: number;
    relay?: OrderDeliveryRelay;
    gift?: OrderDeliveryGift;
    billingAddress?: UserAddress;
    deliveryAddress?: UserAddress;
    payment?: OrderDeliveryPayment;
    billingSameAsDelivery?: boolean;
}

export interface SelectedRelay {
    relayId?: string;
    address?: UserAddress;
}

export interface PaymentResponse {
    returnType?: 'failure' | 'success' | '3ds' | 'redirection' | 'response';
    redirection?: string;
    threeDSecure?: ThreeDSecure;
}

export interface ThreeDSecure {
    md?: string;
    paReq?: string;
    paRes?: string;
    termUrl?: string;
    paymentData?: string;
}

export interface PaymentContext {
    holderName?: string;
    encryptedCardNumber?: string;
    encryptedExpiryMonth?: string;
    encryptedExpiryYear?: string;
    encryptedSecurityCode?: string;
    storeCard?: boolean;
    storedCard?: string;
    securityCode?: string;
    redirection?: string;
    additionalContext?: any[];
}

export interface ExternalPaymentContext {
    type?: string;
    redirection?: string;
    additionalContext?: any[];
}

export interface PaymentRecurringDetails {
    reference?: string;
    variant?: string;
    /** @format byte */
    expiryMonth?: number;
    /** @format int16 */
    expiryYear?: number;
    holderName?: string;
    cardNumber?: string;
}

export interface PaymentMethodResponse {
    paymentMethods: {
        details: any[];
        name: string;
        type: string;
    }[];
}

export interface AdyenPaymentResponse {
    merchantReference?: string;
    donationToken?: string;
    details?: object[];
    paymentData?: string;
    resultCode: string;
    redirect?: {
        data?: object;
        method?: string;
        url?: string;
    };
    pspReference?: string;
    refusalReason?: string;
    action?: {
        paymentData: string;
        paymentMethodType: string;
        url: string;
        method: string;
        type: string;
    };
    additionalData?: {
        domesticShopperAdvice?: string | null;
        domesticRefusalReasonRaw?: string | null;
    };
    paymentMethod?: {
        /**
         * Payment type.
         * @example ["scheme","applepay","ideal"]
         */
        type?: string;
        /**
         * Brand’s paiement type, that the shopper used to pay. For exemple if payment type is Scheme, we can have brand like visa, mastercard, etc.
         * @example ["visa","maestro_applepay","visa_applepay"]
         */
        brand?: string;
        applePayToken?: string;
    };
}

export interface AdyenDonationResponse {
    amount?: {
        currency?: string;
        value?: number;
    };
    donationAccount?: string;
    id?: string;
    merchantAccount?: string;
    payment?: {
        pspReference?: string;
        resultCode?: string;
        merchantReference?: string;
    };
    reference?: string;
    status?: string;
}

export interface Slot {
    id?: number;
    uid?: string;
    start_date?: number;
    end_date?: number;
}

export interface SalesVersionsWithEans {
    sales_versions?: number[];
    eans?: string[];
}

export interface ShippingMode {
    id?: number;
    /** Shipping attribute uid */
    uid?: string;
    label?: string;
    description?: string;
    shippingDelayDescription?: string;
    price?: number;
    /** Price to display, with currency */
    displayPrice?: string;
    currency?: string;
    /** @default true */
    needsAddress?: boolean;
    /** @default false */
    needsRelay?: boolean;
    /** @default false */
    withSlot?: boolean;
    /** @default false */
    isShop?: boolean;
    /** @default false */
    isLastMinuteShopping?: boolean;
    /** @default true */
    isAvailable?: boolean;
    unavailabilityReason?: string | null;
    /** Shipping mode uid */
    shippingModeUid?: string;
    shippingType?: string | null;
}

export interface AlternateUrl {
    lang?: string;
    url?: string;
}

export interface User {
    id?: number | null;
    uuid?: string;
    salesforceId?: string | null;
    email?: string;
    /** @min 0 */
    nbOrders?: number;
    gender?: number | null;
    firstName?: string;
    lastName?: string | null;
    /** @format date-time */
    birthDate?: string | null;
    phone?: string | null;
    sezaneLegacyId?: number | null;
    octobreLegacyId?: number | null;
    /** @default false */
    subscribedToInvitationsEmails?: boolean | null;
    /** @default null */
    newsletterSubscriptionStatus?: boolean | null;
    attributsBatch?: {
        /** @format date-time */
        signup_date?: string | null;
        last_order_source?: string | null;
        /** @format date-time */
        last_order_shipping_date?: string | null;
        /** @format date-time */
        first_order_date?: string | null;
        delivery_zipcode?: string | null;
        last_order_amount?: number | null;
        /** @format date-time */
        last_order_date?: string | null;
    };
}

export interface UserError {
    message?: string;
}

export interface UserState {
    eans?: string[];
    cart?: Order;
}

export interface UserAddress {
    id?: number;
    uid?: string;
    name?: string;
    gender?: number;
    firstName?: string;
    lastName?: string;
    company?: string;
    phoneNumber?: string;
    address?: string;
    addressMore?: string;
    postcode?: string;
    city?: string;
    countryCode?: string;
    country?: string;
    stateCode?: string;
    isDefaultShipping?: boolean;
    isDefaultBilling?: boolean;
    saveAddress?: boolean;
    favorite?: boolean;
    relayId?: string;
    inputType?: string;
    latitude?: number | null;
    longitude?: number | null;
}

export interface UserCredit {
    id?: number;
    uid?: string;
    code?: string;
    amount?: number;
    currencyCode?: string;
    remainingAmount?: number | null;
    /** @format date-time */
    expiredAt?: string;
    /** @format date-time */
    usedAt?: string;
    orderId?: number;
    externalId?: string;
    /** Price to display, with currency */
    displayPrice?: string;
}

export interface PaymentMethod {
    reference?: string;
    variant?: string;
    cardNumber?: string;
    expiryMonth?: number;
    expiryYear?: number;
    holderName?: string;
}

export interface OrderReturn {
    id?: number;
    uid?: string;
    orderId?: string;
    externalId?: string;
    globalEId?: string | null;
    mergedOrderIds?: any[];
    returnMode?: ReturnMode;
    slot?: ReturnSlot;
    address?: ReturnAddress;
    /** @format date-time */
    createdAt?: string;
    status?: string;
    hasWaitingStatus?: boolean;
    externalTracking?: string;
    tracking?: string;
    trackingLink?: string;
    strickerLink?: string;
    orderReturnProducts?: OrderReturnProduct[];
    refundMode?: string;
    /** @default false */
    hasReturnLabel?: boolean;
}

export interface OrderReturnProduct {
    orderItemProductId?: string;
    reason?: string;
    quantity?: number;
    ean?: string;
}

export interface ReturnMode {
    id?: number;
    uid?: string;
    label?: string;
    confirmationTitle?: string;
    confirmationDescription?: string;
    sezaneLegacyId?: number | null;
    octobreLegacyId?: number | null;
    type?: string | null;
}

export interface ReturnAddress {
    company?: string;
    lastName?: string;
    firstName?: string;
    address?: string;
    country?: string;
    countryId?: number;
    postalCode?: string;
    city?: string;
    timezone?: string;
    phoneNumber?: string;
    extra?: string;
}

export interface ReturnSlot {
    id?: number;
    uid?: string;
    /** @format date-time */
    startDate?: string;
    /** @format date-time */
    endDate?: string;
}

export interface EcardPreview {
    amount?: number;
    senderFirstname?: string;
    senderLastname?: string;
    receiverFirstname?: string;
    receiverLastname?: string;
    message?: string;
}

export interface EvergreenMedia {
    mediaUrl?: string;
}

export interface SizeGuide {
    title?: string;
    subtitle?: string | null;
    howTo?: Measurement[];
    sizeChart?: Size[];
    conversions?: Conversion[];
}

export interface HowTo {
    imageUrl?: string;
    howToMeasure?: string;
    instructions?: {
        title?: string;
        description?: string;
    }[];
}

export interface SizePart {
    name?: string;
    code?: string;
    measurements?: Measurement[];
}

export interface Measurement {
    values?: string[];
    unit?: string;
}

export interface Conversion {
    universalSize?: string;
    conversionParts?: ConversionPart[];
}

export interface ConversionPart {
    name?: string;
    countries?: string[];
}

export interface EmailTemplate {
    id?: string;
    name?: string;
    previewUrl?: string;
}

export interface Ecard {
    amountSelect?: number;
    amount?: number;
    fromFirstname?: string;
    fromLastname?: string;
    for?: string;
    forFirstname?: string;
    forLastname?: string;
    /** @format date-time */
    sendDate?: string;
    /** @default "" */
    message?: string;
}

export interface NewsletterEmail {
    email?: string;
}

export interface ResponseJSON {
    data?: string;
    errors?: {
        status?: number;
        title?: string;
    }[];
}

export interface Error {
    message?: string;
    validation?: boolean;
    violations?: object;
}

export interface StockOperation {
    ean: string;
    logisticSite: string;
    buckets: Record<string, number>;
}

export interface StockBatchUpdate {
    operations?: StockOperation[];
    reference?: string;
    cause?: string;
}

export interface StockBatchSet {
    operations?: StockOperation[];
    reference?: string;
    cause?: string;
}

export interface StockBatchDelete {
    operations?: StockOperation[];
    reference?: string;
    cause?: string;
}

export interface Stock {
    ean?: string;
    logisticSite?: string;
    buckets?: Record<string, number>;
}

export interface StockList {
    results?: Stock[];
    totalCount?: number;
    page?: number;
}

export interface StockSearch {
    eans?: string[];
    buckets?: string[] | null;
    logisticSites?: string[] | null;
}

export interface GlobalECheckoutCartInfo {
    productsList?: GlobalEProduct[];
    clientIP?: string | null;
    countryCode?: string | null;
    shippingDetails?: GlobalEUserDetails;
    billingDetails?: GlobalEUserDetails;
    userDetails?: GlobalECartUserDetails;
    includeVAT?: number | null;
    discountsList?: GlobalEDiscount[];
    currencyCode?: string | null;
    originalCurrencyCode?: string | null;
    cultureCode?: string | null;
    shippingOptionsList?: GlobalEShippingOption;
    hubId?: number | null;
    loyaltyPointsTotal?: number | null;
    loyaltyPointsEarned?: number | null;
    loyaltyPointsSpent?: number | null;
    loyaltyCode?: string | null;
    vatRegistrationNumber?: string | null;
    doNotChargeVAT?: boolean | null;
    IsFreeShipping?: boolean | null;
    FreeShippingCouponCode?: string | null;
    paymentInstallments?: string | null;
    urlParameters?: GlobalEKeyValuePair[];
    otVoucherCode?: string | null;
    otVoucherCurrencyCode?: string | null;
    otVoucherAmount?: number | null;
    rateData?: string | null;
    webStoreCode?: string | null;
    webStoreInstanceCode?: string | null;
    LoyaltyPoints?: GlobalELoyaltyPoints;
    merchantCartHash?: string | null;
    allowMailsFromMerchant?: boolean | null;
    cartId?: string | null;
}

export interface GlobalEProduct {
    ProductCode?: string;
    ProductGroupCode?: string | null;
    ProductCodeSecondary?: string | null;
    ProductGroupCodeSecondary?: string | null;
    CartItemId?: string | null;
    ParentCartItemId?: string | null;
    CartItemOptionId?: string | null;
    Name?: string;
    NameEnglish?: string;
    Description?: string;
    DescriptionEnglish?: string;
    Keywords?: string | null;
    URL?: string | null;
    GiftMessage?: string | null;
    GenericHSCode?: string | null;
    OriginCountryCode?: string | null;
    Weight?: number | null;
    NetWeight?: number | null;
    Height?: number | null;
    Width?: number | null;
    Length?: number | null;
    Volume?: number | null;
    NetVolume?: number | null;
    ImageURL?: string | null;
    ImageHeight?: number | null;
    ImageWidth?: number | null;
    ListPrice?: string | null;
    OriginalListPrice?: string | null;
    SalePrice?: string | null;
    SalePriceBeforeRounding?: number | null;
    LineItemSalePrice?: number | null;
    OriginalSalePrice?: string | null;
    LineItemOriginalSalePrice?: number | null;
    SalePriceReason?: string | null;
    IsFixedPrice?: boolean | null;
    OrderedQuantity?: number | null;
    DeliveryQuantity?: number | null;
    IsBundle?: boolean | null;
    IsVirtual?: boolean | null;
    IsBlockedForGlobalE?: boolean | null;
    HandlingCode?: string | null;
    VATRateType?: GlobalEVATRateType;
    LocalVATRateType?: GlobalEVATRateType;
    VATCategory?: GlobalEVATRateType;
    Brand?: GlobalEBrand;
    Categories?: GlobalECategory[];
    Attributes?: GlobalEAttribute[];
    AttributesEnglish?: GlobalEAttribute[];
    IsBackOrdered?: boolean | null;
    BackOrderDate?: string | null;
    ProductClassCode?: string | null;
    PriceCoefficientRate?: number | null;
    MetaData?: GlobalEProductMetaData;
}

export type GlobalEUserDetails = {
    UserId?: string | null;
    UserIdNumber?: string | null;
    UserIdNumberType?: GlobalEUserIdNumberType;
    FirstName?: string | null;
    LastName?: string | null;
    MiddleName?: string | null;
    Salutation?: string | null;
    Phone1?: string | null;
    Phone2?: string | null;
    Fax?: string | null;
    Email?: string | null;
    Company?: string | null;
    Address1?: string | null;
    Address2?: string | null;
    City?: string | null;
    StateOrProvice?: string | null;
    StateCode?: string | null;
    Zip?: string | null;
    CountryCode?: string | null;
    CountryName?: string | null;
};

export type GlobalEUserIdNumberType = {
    UserIdNumberTypeCode?: string;
    Name?: string;
} | null;

export type GlobalECartUserDetails = {
    UserId?: string | null;
    AddressDetails?: GlobalEAddressDetails[];
};

export interface GlobalEAddressDetails {
    UserIdNumber?: string | null;
    UserIdNumberType?: GlobalEUserIdNumberType;
    FirstName?: string | null;
    LastName?: string | null;
    MiddleName?: string | null;
    Salutation?: string | null;
    Phone1?: string | null;
    Phone2?: string | null;
    Fax?: string | null;
    Email?: string | null;
    Company?: string | null;
    Address1?: string | null;
    Address2?: string | null;
    City?: string | null;
    StateOrProvice?: string | null;
    StateCode?: string | null;
    Zip?: string | null;
    CountryCode?: string | null;
    IsShipping?: boolean | null;
    IsBilling?: boolean | null;
    IsDefaultShipping?: boolean | null;
    IsDefaultBilling?: boolean | null;
    AddressBookId?: string | null;
    AddressBookName?: string | null;
}

export type GlobalEDiscount = {
    OriginalDiscountValue?: number;
    VATRate?: number;
    LocalVATRate?: number | null;
    DiscountValue?: number;
    Name?: string;
    Description?: string | null;
    CouponCode?: string | null;
    ProductCartItemId?: string | null;
    DiscountCode?: string | null;
    LoyaltyVoucherCode?: string | null;
    DiscountType?: number;
    CalculationMode?: number | null;
};

export type GlobalEShippingOption = {
    Carrier?: string;
    CarrierName?: string;
    CarrierTitle?: string;
    Code?: string;
    Method?: string;
    MethodTitle?: string;
    MethodDescription?: string;
    Price?: number;
} | null;

export interface GlobalEKeyValuePair {
    Key?: string;
    Value?: string;
}

export type GlobalELoyaltyPoints = {
    LoyaltyPointsTotal?: number;
    LoyaltyPointsMaxSpend?: number | null;
    LoyaltyPointsValueForSpend?: number | null;
    LoyaltyPointsOriginalValueForSpend?: number | null;
    LoyaltyPointsValueForEarn?: number | null;
    LoyaltyPointsOriginalValueForEarn?: number | null;
};

export type GlobalEVATRateType = {
    VATRateTypeCode?: string;
    Name?: string | null;
    Rate?: number;
};

export type GlobalEBrand = {
    BrandCode?: string;
    Name?: string;
} | null;

export interface GlobalECategory {
    CategoryCode?: string;
    Name?: string;
}

export interface GlobalEAttribute {
    AttributeCode?: string;
    Name?: string;
    AttributeTypeCode?: string;
}

export type GlobalEProductMetaData = {
    Attributes?: GlobalECustomProductAttribute[];
} | null;

export interface GlobalECustomProductAttribute {
    AttributeKey?: string;
    AttributeValue?: string;
}

export interface GlobalEOrderStatusDetails {
    OrderId?: string;
    OrderStatus?: GlobalEOrderStatus;
    OrderStatusReason?: GlobalEOrderStatusReason;
    OrderComments?: string | null;
    ConfirmationNumber?: string | null;
    TrackingServiceName?: string | null;
    TrackingServiceSite?: string | null;
    TrackingNumber?: string | null;
    TrackingURL?: string | null;
    DeliveryReferenceNumber?: string | null;
}

export type GlobalEOrderStatus = {
    OrderStatusCode?: string;
    Name?: string;
} | null;

export type GlobalEOrderStatusReason = {
    OrderStatusReasonCode?: string;
    Name?: string;
} | null;

export interface GlobalEResponseInfo {
    Success?: boolean;
    Reason?: string;
}

export interface GlobalEOrderRefund {
    MerchantOrderId?: string | null;
    RefundId?: string | null;
    MerchantGUID?: string | null;
    Products?: GlobalERefundProduct[];
    Components?: GlobalEOrderRefundComponent[];
    OrderId?: string | null;
    TotalRefundAmount?: string | null;
    RefundReason?: GlobalEOrderRefundReason;
    RefundComments?: string | null;
    OriginalTotalRefundAmount?: string | null;
    ServiceGestureAmount?: string | null;
    WebStoreCode?: string | null;
    WebStoreInstanceCode?: string | null;
    /** @format decimal */
    TotalGiftCardsRefundAmountinMerchantCurrency?: number | null;
}

export interface GlobalEOrderRefundDetails {
    OrderId?: string;
    TotalRefundAmount?: number | null;
    OriginalTotalRefundAmount?: number | null;
    RefundReason?: GlobalEOrderRefundReason;
    RefundComments?: string | null;
    DutiesAmount?: number | null;
    ShippingAmount?: number | null;
    ServiceGestureAmount?: number | null;
    FullRefund?: boolean | null;
    ProductsDutiesRefund?: boolean | null;
}

export type GlobalEOrderRefundReason = {
    OrderRefundReasonCode?: string;
    Name?: string;
} | null;

export type GlobalEOrderRefundComponent = {
    Amount?: string;
    OriginalAMount?: string;
    /** \"TRUE\" or \"FALSE\" */
    IsChargedToMerchant?: string;
    /** Products, Shipping, Duties, PrepaidReturn or ServiceGesture */
    ComponentType?: string;
} | null;

export interface GlobalERefundProduct {
    CartItemId?: string;
    /** can be int or string */
    RefundQuantity?: any;
    OriginalRefundAmount?: string | null;
    RefundAmount?: string | null;
    RefundReason?: GlobalEOrderRefundReason;
    RefundComments?: string | null;
}

export interface GlobalEMerchantOrder {
    MerchantGUID?: string;
    OrderId?: string;
    MerchantOrderId?: string | null;
    CartId?: string | null;
    CartHash?: string | null;
    StatusCode?: string;
    CurrencyCode?: string;
    PriceCoefficientRate?: number;
    RoundingRate?: number;
    UserId?: string | null;
    ShippingMethodCode?: string | null;
    IsFreeShipping?: boolean | null;
    CustomerComments?: string | null;
    Products?: GlobalEMerchantProduct[];
    Discounts?: GlobalEMerchantDiscount[];
    PrimaryBilling?: GlobalEMerchantCustomerDetails;
    PrimaryShipping?: GlobalEMerchantCustomerDetails;
    PaymentDetails?: GlobalEMerchantPaymentDetails;
    InternationalDetails?: GlobalEMerchantInternationalDetails;
    DiscountedShippingPrice?: number;
}

export interface GlobalEMerchantResponseInfo {
    InternalOrderId?: string | null;
    OrderId?: string | null;
    StatusCode?: string | null;
    PaymentCurrencyCode?: string | null;
    PaymentAmount?: string | null;
    Success?: boolean;
    ErrorCode?: string | null;
    Message?: string | null;
    Description?: string | null;
}

export interface GlobalEMerchantPaymentDetails {
    OwnerFirstName?: string | null;
    OwnerLastName?: string | null;
    OwnerName?: string | null;
    CardNumber?: string;
    CVVNumber?: string;
    PaymentMethodName?: string;
    PaymentMethodCode?: string;
    PaymentMethodTypeCode?: string;
    ExpirationDate?: string;
    CountryName?: string;
    CountryCode?: string;
    StateCode?: string | null;
    StateOrProvice?: string | null;
    City?: string | null;
    Zip?: string | null;
    Address1?: string | null;
    Address2?: string | null;
    Phone1?: string | null;
    Phone2?: string | null;
    Fax?: string | null;
    Email?: string | null;
}

export interface GlobalEMerchantProduct {
    Sku?: string;
    Price?: number;
    VATRate?: number;
    InternationalPrice?: number | null;
    RoundingRate?: number;
    Quantity?: number;
    CartItemId?: string | null;
    IsBackOrdered?: boolean | null;
    BackOrderDate?: string | null;
    DiscountedPrice?: number;
    InternationalDiscountedPrice?: number;
}

export interface GlobalEMerchantDiscount {
    Name?: string;
    Price?: number;
    VATRate?: number;
    LocalVATRate?: number | null;
    InternationalPrice?: number | null;
    Description?: string | null;
    CouponCode?: string | null;
    ProductCartItemId?: string | null;
    DiscountCode?: string | null;
    LoyaltyVoucherCode?: string | null;
    DiscountType?: number;
}

export interface GlobalEMerchantCustomerDetails {
    FirstName?: string | null;
    LastName?: string | null;
    MiddleName?: string | null;
    Salutation?: string | null;
    Phone1?: string | null;
    Phone2?: string | null;
    Fax?: string | null;
    Email?: string | null;
    Company?: string | null;
    Address1?: string | null;
    Address2?: string | null;
    City?: string | null;
    StateOrProvice?: string | null;
    StateCode?: string | null;
    Zip?: string | null;
    CountryCode?: string | null;
    CountryCode3?: string | null;
    CountryName?: string | null;
}

export interface GlobalEMerchantInternationalDetails {
    CurrencyCode?: string;
    TotalPrice?: number;
    TransactionCurrencyCode?: string;
    TransactionTotalPrice?: number;
    TotalShippingPrice?: number;
    TotalDutiesPrice?: number;
    PaymentMethodCode?: string;
    PaymentMethodName?: string;
    DutiesGuaranteed?: boolean;
    DiscountedShippingPrice?: number;
}

export interface GlobalEOrderRefundProduct {
    CartItemId?: string;
    /** can be int or string */
    RefundQuantity?: any;
    RefundAmount?: string;
    OriginalRefundAmount?: string;
    RefundReason?: GlobalEOrderRefundReason;
    RefundComments?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(key => 'undefined' !== typeof query[key]);
        return keys
            .map(key =>
                Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string' ? JSON.stringify(input) : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                        ? JSON.stringify(property)
                        : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
    };

    protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter = this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
            },
            signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
            body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
        }).then(async response => {
            const r = response as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then(data => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch(e => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title Sezane ecommerce public API
 * @version 0.0.1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    ecommercePublic = {
        /**
         * No description
         *
         * @tags store
         * @name GetBrandWebsites
         * @summary Get all enabled websites for a brand
         * @request GET:/ecommerce-public/v1/sites/{brand}
         */
        getBrandWebsites: (
            brand: string,
            query?: {
                /** allowed sites for this instance (example: CN in China) */
                allowedSites?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<SitesList, any>({
                path: `/ecommerce-public/v1/sites/${brand}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * @description Retrieve an order or a cart by its id (not encrypted for now)
         *
         * @tags order
         * @name GetOrder
         * @summary Get a store order
         * @request GET:/ecommerce-public/v1/order/{id}
         */
        getOrder: (
            id: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Workspace id, used only for impersonation */
                workspace?: number;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
                /**
                 * True if its a globale order
                 * @default false
                 */
                globale?: boolean;
                /**
                 * Check the stock for packaging options
                 * @default true
                 */
                checkPackagingStock?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<Order, void>({
                path: `/ecommerce-public/v1/order/${id}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * @description Update order
         *
         * @tags order
         * @name UpdateOrder
         * @summary Update an order
         * @request PUT:/ecommerce-public/v1/order/{id}
         */
        updateOrder: (
            id: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Workspace id, used only for impersonation */
                workspace?: number;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
                /**
                 * True if its a globale order
                 * @default false
                 */
                globale?: boolean;
            },
            data: {
                /** Site code */
                site?: string;
                /** Currency code */
                currencyCode?: string;
                /** Currency legacy numeric id */
                currencyId?: number;
                /** Country code */
                country?: string;
                /** Locale code */
                locale?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<Order, void>({
                path: `/ecommerce-public/v1/order/${id}`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name AddProductOnOrder
         * @summary Add one or more product(s) on Order
         * @request POST:/ecommerce-public/v1/order/{id}/product
         */
        addProductOnOrder: (
            id: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Workspace id, used only for impersonation */
                workspace?: number;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
                /**
                 * True if its a globale order
                 * @default false
                 */
                globale?: boolean;
                /** Is the product to add a last minute shopping */
                last_minute: boolean;
                /** The promotional campaign to which the product is attached */
                campaignId?: number;
            },
            data: {
                /** EAN of the product(s) to attach on Order. If campaignId is specified, eans can be an empty array when withChoice = false in the campaign list endpoint. */
                eans: string[];
                multiProductUid?: string | null;
                /** Customisation text in as many rows as lines of text */
                customizations?: string[] | null;
                /** Customisation color */
                color?: string | null;
                /** Cutomisation position */
                position?: string | null;
                /** Cutomisation type (eg. gravure, broderie, etc) */
                type?: string | null;
                /** Cutomisation format (eg. texte, icône, etc) */
                format?: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                Order,
                {
                    message?: string;
                } | void
            >({
                path: `/ecommerce-public/v1/order/${id}/product`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name UpdateProductOnOrder
         * @summary Update one row on Order
         * @request PUT:/ecommerce-public/v1/order/{id}/product/{orderItemProduct}
         */
        updateProductOnOrder: (
            id: string,
            orderItemProduct: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Workspace id, used only for impersonation */
                workspace?: number;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
                /**
                 * True if its a globale order
                 * @default false
                 */
                globale?: boolean;
            },
            data: {
                quantity?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<Order, OrderError | void>({
                path: `/ecommerce-public/v1/order/${id}/product/${orderItemProduct}`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name UpdateCustomisation
         * @summary Update a customisation
         * @request POST:/ecommerce-public/v1/order/{id}/product/{orderItemProduct}/customisation
         */
        updateCustomisation: (
            id: string,
            orderItemProduct: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Workspace id, used only for impersonation */
                workspace?: number;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
                /**
                 * True if its a globale order
                 * @default false
                 */
                globale?: boolean;
            },
            data: OrderItemCustomisation,
            params: RequestParams = {}
        ) =>
            this.request<Order, any>({
                path: `/ecommerce-public/v1/order/${id}/product/${orderItemProduct}/customisation`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name UpdatePackagingOptions
         * @summary Update packaging options on an Order
         * @request PUT:/ecommerce-public/v1/order/{id}/packaging
         */
        updatePackagingOptions: (
            id: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Workspace id, used only for impersonation */
                workspace?: number;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
                /**
                 * True if its a globale order
                 * @default false
                 */
                globale?: boolean;
            },
            data: {
                options?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<Order, OrderError | void>({
                path: `/ecommerce-public/v1/order/${id}/packaging`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name AddCredit
         * @summary Add a credit on Order
         * @request POST:/ecommerce-public/v1/order/{id}/credit
         */
        addCredit: (
            id: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Workspace id, used only for impersonation */
                workspace?: number;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
                /**
                 * True if its a globale order
                 * @default false
                 */
                globale?: boolean;
            },
            data: {
                voucherCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<Order, OrderError | void>({
                path: `/ecommerce-public/v1/order/${id}/credit`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name RemoveCredit
         * @summary Remove a credit on Order
         * @request DELETE:/ecommerce-public/v1/order/{id}/credit/{code}
         */
        removeCredit: (
            id: string,
            code: number,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Workspace id, used only for impersonation */
                workspace?: number;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
                /**
                 * True if its a globale order
                 * @default false
                 */
                globale?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<Order, OrderError | void>({
                path: `/ecommerce-public/v1/order/${id}/credit/${code}`,
                method: 'DELETE',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name AddCodeOnOrder
         * @summary Add a voucher or use an ecard with its code on an order
         * @request POST:/ecommerce-public/v1/order/{id}/code
         */
        addCodeOnOrder: (
            id: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Workspace id, used only for impersonation */
                workspace?: number;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
                /**
                 * True if its a globale order
                 * @default false
                 */
                globale?: boolean;
            },
            data: {
                /** Code to attach as a voucher, an ecard or a commercial operation */
                code?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    type?: 'discount' | 'ecard' | 'campaign';
                    order?: Order;
                },
                OrderError | void
            >({
                path: `/ecommerce-public/v1/order/${id}/code`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name RemoveVoucherOrEcardCode
         * @summary Remove a promotional code on Order
         * @request DELETE:/ecommerce-public/v1/order/{id}/code/{code}
         */
        removeVoucherOrEcardCode: (
            id: string,
            code: number,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Workspace id, used only for impersonation */
                workspace?: number;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
                /**
                 * True if its a globale order
                 * @default false
                 */
                globale?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<Order, OrderError | void>({
                path: `/ecommerce-public/v1/order/${id}/code/${code}`,
                method: 'DELETE',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name UpdateEcardMessageFromOrder
         * @summary Update message on an Ecard
         * @request PUT:/ecommerce-public/v1/order/{id}/ecard/{lineItemUuid}
         */
        updateEcardMessageFromOrder: (
            id: string,
            lineItemUuid: string,
            query: {
                /** Site code */
                site: string;
                brand: string;
            },
            data: {
                message?: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<Order, any>({
                path: `/ecommerce-public/v1/order/${id}/ecard/${lineItemUuid}`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name GetPromotionalCampaign
         * @summary Get all promotional campaigns available for the order
         * @request GET:/ecommerce-public/v1/order/{id}/promotional-campaign
         */
        getPromotionalCampaign: (
            id: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Workspace id, used only for impersonation */
                workspace?: number;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
                /**
                 * True if its a globale order
                 * @default false
                 */
                globale?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PromotionalCampaign[], OrderError | void>({
                path: `/ecommerce-public/v1/order/${id}/promotional-campaign`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name DeletePromotionalCampaignOnOrder
         * @summary Remove promotional campaign
         * @request DELETE:/ecommerce-public/v1/order/{id}/promotional-campaign/{campaignId}
         */
        deletePromotionalCampaignOnOrder: (
            id: string,
            campaignId: number,
            query: {
                /** Site code */
                site: string;
                /** Workspace id, used only for impersonation */
                workspace?: number;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
                /**
                 * True if its a globale order
                 * @default false
                 */
                globale?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<Order, OrderError | void>({
                path: `/ecommerce-public/v1/order/${id}/promotional-campaign/${campaignId}`,
                method: 'DELETE',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags front, push-products
         * @name GetPushProducts
         * @summary Get push products
         * @request GET:/ecommerce-public/v1/push-products
         */
        getPushProducts: (
            query: {
                /** Site code */
                site: string;
                /** Brand code */
                brand: string;
                /** Locale */
                locale?: string;
                /** Currency code */
                currency?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PushProduct[], any>({
                path: `/ecommerce-public/v1/push-products`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags cms
         * @name GetPopins
         * @summary Get the popins for URL or static page
         * @request GET:/ecommerce-public/v1/store-cms-popins
         */
        getPopins: (
            query?: {
                /** site code */
                site?: string;
                brand?: string;
                locale?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<Popin[], any>({
                path: `/ecommerce-public/v1/store-cms-popins`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @name FrontApplepayValidation
         * @summary validate merchant account from apple pay server
         * @request POST:/ecommerce-public/v1/applepay/validation
         */
        frontApplepayValidation: (
            query: {
                /** brand code */
                brand: string;
                /** site code */
                site: string;
            },
            data: {
                /** @format uri */
                refererUri: string;
                currency: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<any, any>({
                path: `/ecommerce-public/v1/applepay/validation`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @name PostUserOrderPaymentMethods
         * @summary Get available payment methods
         * @request POST:/ecommerce-public/v1/user/{keycloackUuid}/order/{orderId}/payment/methods
         */
        postUserOrderPaymentMethods: (
            orderId: string,
            keycloackUuid: string,
            query: {
                /** brand code */
                brand: string;
                /** site code */
                site: string;
            },
            data: {
                shopperLocale?: string;
                countryCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                PaymentMethodResponse,
                {
                    message?: string;
                    code?: string;
                }
            >({
                path: `/ecommerce-public/v1/user/${keycloackUuid}/order/${orderId}/payment/methods`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @name PostUserOrderPayment
         * @summary Make a payment
         * @request POST:/ecommerce-public/v1/user/{keycloackUuid}/order/{orderId}/payment
         */
        postUserOrderPayment: (
            orderId: string,
            keycloackUuid: string,
            query: {
                /** brand code */
                brand: string;
                /** site code */
                site: string;
            },
            data: {
                returnUrl?: string;
                paymentMethod?: object;
                origin?: string;
                browserInfo?: object;
                /** @default false */
                storePaymentMethod?: boolean;
                /** @default false */
                legalTermsChecked: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                AdyenPaymentResponse,
                {
                    message?: string;
                    code?: string;
                } | void
            >({
                path: `/ecommerce-public/v1/user/${keycloackUuid}/order/${orderId}/payment`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @name PostUserOrderPaymentDetails
         * @summary Make a payment
         * @request POST:/ecommerce-public/v1/user/{keycloackUuid}/order/{orderId}/payment/details
         */
        postUserOrderPaymentDetails: (
            orderId: string,
            keycloackUuid: string,
            query: {
                /** brand code */
                brand: string;
                /** site code */
                site: string;
            },
            data: {
                paymentData?: string;
                details: object;
                merchantReference?: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<AdyenPaymentResponse, any>({
                path: `/ecommerce-public/v1/user/${keycloackUuid}/order/${orderId}/payment/details`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @name PostUserOrderDonation
         * @summary Make a donation
         * @request POST:/ecommerce-public/v1/donations
         */
        postUserOrderDonation: (
            orderId: string,
            keycloackUuid: string,
            query: {
                /** brand code */
                brand: string;
                /** site code */
                site: string;
            },
            data: {
                donationToken: string | null;
                paymentMethod: {
                    /** Card: "scheme", iDeal: "sepadirectdebit", Apple Pay: "applepay", etc */
                    type?: string | null;
                    brand?: string | null;
                    applePayToken?: string | null;
                };
                amount: {
                    value?: number | null;
                    currency?: string | null;
                };
                donationOriginalPspReference: string | null;
                /** If you need to provide multiple references for a transaction, separate them with semicolon (";"). */
                reference: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                AdyenDonationResponse,
                {
                    message?: string;
                    code?: string;
                }
            >({
                path: `/ecommerce-public/v1/donations`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description used to abort the payment process (eq: when the user abort the paypal payment process), cancel every created orders from the OMS
         *
         * @name PostOrderPaymentAbort
         * @summary abort the payment process
         * @request POST:/ecommerce-public/v1/user/{keycloackUuid}/order/{orderId}/payment/abort
         */
        postOrderPaymentAbort: (
            orderId: string,
            keycloackUuid: string,
            query: {
                /** brand code */
                brand: string;
                /** site code */
                site: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/ecommerce-public/v1/user/${keycloackUuid}/order/${orderId}/payment/abort`,
                method: 'POST',
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-payment
         * @name SaveBilling
         * @summary Set the billing address for the provided order.
         * @request POST:/ecommerce-public/v1/payment/order-billing/{orderId}
         */
        saveBilling: (
            orderId: string,
            country: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
            },
            data?: OrderDelivery,
            params: RequestParams = {}
        ) =>
            this.request<Order, any>({
                path: `/ecommerce-public/v1/payment/order-billing/${orderId}`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-payment
         * @name GetOrderDeliveryChoices
         * @summary Get an all delivery choices from API (stored cards, users addresses, etc.).
         * @request GET:/ecommerce-public/v1/payment/order-delivery-choices/{orderId}/{country}
         */
        getOrderDeliveryChoices: (
            orderId: string,
            country: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** currency code */
                currency?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<OrderDeliveryChoices, void>({
                path: `/ecommerce-public/v1/payment/order-delivery-choices/${orderId}/${country}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-payment
         * @name SaveShippingMode
         * @summary Set the shipping mode and additional data for the provided order.
         * @request POST:/ecommerce-public/v1/payment/order-shipping-mode/{orderId}/{country}/{shippingModeId}
         * @deprecated
         */
        saveShippingMode: (
            orderId: string,
            country: string,
            shippingModeId: number,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Slot Id. */
                slotId?: number;
            },
            data?: OrderDelivery,
            params: RequestParams = {}
        ) =>
            this.request<Order, any>({
                path: `/ecommerce-public/v1/payment/order-shipping-mode/${orderId}/${country}/${shippingModeId}`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-payment
         * @name SaveShippingModeWithUuid
         * @summary Set the shipping mode and additional data for the provided order.
         * @request POST:/ecommerce-public/v1/payment/order-shipping-mode-uuid/{orderId}/{country}/{shippingModeUuid}
         */
        saveShippingModeWithUuid: (
            orderId: string,
            country: string,
            shippingModeUuid: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Slot Id. */
                slotId?: number;
            },
            data?: OrderDelivery,
            params: RequestParams = {}
        ) =>
            this.request<Order, any>({
                path: `/ecommerce-public/v1/payment/order-shipping-mode-uuid/${orderId}/${country}/${shippingModeUuid}`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, delivery
         * @name GetOrderDeliveryRelay
         * @summary Get a set of relay points by postcode/city or latitude/longitude.
         * @request GET:/ecommerce-public/v1/delivery/order-delivery-relay/{shippingMode}/{country}
         */
        getOrderDeliveryRelay: (
            shippingMode: string,
            country: string,
            query?: {
                /** Site code */
                site?: string;
                /** Brand code */
                brand?: string;
                /** The postcode if we want to search by postcode/city. */
                postcode?: string;
                /** The city if we want to search by postcode/city. */
                city?: string;
                /** The latitude in case we want to search by geo coordinates in XSD format (ex 41.403). */
                lat?: string;
                /** The longitude in case we want to search by geo coordinates in XSD format (ex 2.174). */
                long?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<RelayChoices, void>({
                path: `/ecommerce-public/v1/delivery/order-delivery-relay/${shippingMode}/${country}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user-adress
         * @name GetUserAddresses
         * @summary Get all Addresses for one user
         * @request GET:/ecommerce-public/v1/user/{keycloakUserId}/addresses
         */
        getUserAddresses: (
            keycloakUserId: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Retrieve addresses only for this country */
                countryCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<UserAddress[], any>({
                path: `/ecommerce-public/v1/user/${keycloakUserId}/addresses`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user-adress
         * @name UpdateUserAddress
         * @summary Update User Address
         * @request PUT:/ecommerce-public/v1/user/{keycloakUserId}/address/{addressId}
         */
        updateUserAddress: (
            keycloakUserId: string,
            addressId: string,
            query: {
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
            },
            data: UserAddress,
            params: RequestParams = {}
        ) =>
            this.request<UserAddress, void>({
                path: `/ecommerce-public/v1/user/${keycloakUserId}/address/${addressId}`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags credit, user
         * @name GetUserCredits
         * @summary Get all credits for one user
         * @request GET:/ecommerce-public/v1/user/{keycloakUserId}/user-credits
         */
        getUserCredits: (
            keycloakUserId: string,
            query: {
                page?: number;
                /** Brand code */
                brand: string;
                /** Site code */
                site: string;
                /** Locale code (ex: fr) */
                locale?: string;
                /** Currency code (ex: EUR) */
                currency?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<UserCredit[], any>({
                path: `/ecommerce-public/v1/user/${keycloakUserId}/user-credits`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @name GetEvergreenMedia
         * @summary Get an evergreen media
         * @request GET:/ecommerce-public/v1/evergreen-media/{name}
         */
        getEvergreenMedia: (
            name: string,
            query: {
                /** brand */
                brand: string;
                /** brand */
                site: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<EvergreenMedia, any>({
                path: `/ecommerce-public/v1/evergreen-media/${name}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),
    };
}
