import { getFrontUrl } from './brand';

export const redirectToKeycloak = (redirectUrl?: string) => {
    const queryParams = new URLSearchParams();
    queryParams.append('redirect', redirectUrl || window.location.href);

    window.location.href = `${getFrontUrl()}/${getSiteCodeFromUrl()}/connect?${queryParams.toString()}`;
};

export const getSiteCodeFromUrl = () => {
    return window.location.pathname.split('/')[1] || 'fr';
};
