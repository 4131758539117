import uniqBy from 'lodash/uniqBy';
import { useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { useAppContext } from '../../context/AppContext';
import { useAuth } from '../../context/AuthContext';
import useCurrency from '../../hooks/useCurrency';
import { Messages } from '../../types';
import { ShippingMode as ShippingModeType } from '../../types/api';
import apiClient from '../../utils/api';
import { getDefaultShippingMode } from '../../utils/shipping';
import Radio from '../Form/Radio/Radio';
import Loader from '../Loader/Loader';

export const FETCH_SHIPPING_MODES_KEY = 'shippingModes';

defineMessages({
    store: { id: 'front.summary.delivery_mode.type.label.store' },
});

interface ShippingModeProps {
    countryCode: string;
    onChangeMode: (mode: ShippingModeType) => void;
    quickDeliveryStep: boolean;
    shippingMode?: ShippingModeType;
}

const ShippingMode = ({ onChangeMode, shippingMode, countryCode, quickDeliveryStep }: ShippingModeProps) => {
    const intl = useIntl();
    const { authenticated } = useAuth();
    const { site, brand, orderId } = useAppContext();
    const currency = useCurrency();

    const { data, isLoading } = useQuery(
        [FETCH_SHIPPING_MODES_KEY, countryCode],
        () =>
            apiClient.getOrderDeliveryChoices(orderId, countryCode!, {
                site: site.code!,
                brand,
                currency: currency?.code,
                // @ts-expect-error
                locale: intl.locale,
            }),
        {
            enabled: authenticated,
        }
    );

    const shippingModes = data
        ? uniqBy(
              data.data.availableShippingModes?.filter(mode =>
                  quickDeliveryStep ? mode.isLastMinuteShopping : !mode.isLastMinuteShopping
              ),
              'uid'
          )
        : [];
    const shops = shippingModes?.filter(shippingMode => shippingMode.isShop) || [];
    const otherShippingModes = shippingModes?.filter(shippingMode => !shippingMode.isShop) || [];
    const isShopSelected = !!shippingMode?.isShop;
    const formatMode = (mode: keyof Messages) => (mode.startsWith('front.') ? intl.formatMessage({ id: mode }) : mode);

    // Select default shipping mode
    useEffect(() => {
        if (!shippingMode && shippingModes && shippingModes?.length > 0) {
            const defaultShippingMode = getDefaultShippingMode(shippingModes);

            if (defaultShippingMode) {
                onChangeMode(defaultShippingMode);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shippingMode, shippingModes]);

    return (
        <div className="o-grid o-grid--gutter-sm u-mt-lg">
            <div className="o-grid__col o-grid__col--24 o-grid__col--6@md-plus">
                <p className="c-summary-checkout__subtitle">
                    <FormattedMessage id="shipping.mode" />
                </p>
            </div>

            <div className="o-grid__col o-grid__col--24 o-grid__col--18@md-plus">
                <div className="o-grid o-grid--gutter-sm">
                    <div className="o-grid__col o-grid__col--24 o-grid__col--19@md-plus">
                        {isLoading && <Loader />}
                        {!isLoading && shippingModes && shippingModes.length === 0 && (
                            <p className="u-color-error">
                                <FormattedMessage id="shipping.no_shipping_modes_found" />
                            </p>
                        )}
                        {!isLoading && shippingModes && (
                            <ul className="u-list-unstyled">
                                {shops?.length > 0 && (
                                    <li className="u-mb-xs-bis">
                                        <Radio
                                            id={`shippingMode-shops`}
                                            name="shippingMode"
                                            label={`${formatMode(
                                                'front.summary.delivery_mode.type.label.store'
                                            )} - ${intl.formatMessage({ id: 'shipping.free' })}`}
                                            onChange={() => {
                                                onChangeMode(shops[0]);
                                            }}
                                            checked={isShopSelected}
                                        />
                                        {isShopSelected && (
                                            <ul className="u-list-unstyled u-mt-n u-ml-md">
                                                {shops.map(shop => {
                                                    return (
                                                        <li key={shop.uid} className="u-mt-xs u-mb-xs">
                                                            <Radio
                                                                id={`shippingModeShops-${shop.uid}`}
                                                                name="shippingModeShops"
                                                                label={shop.label}
                                                                onChange={() => onChangeMode(shop)}
                                                                checked={
                                                                    !!(shippingMode && shippingMode.uid === shop.uid)
                                                                }
                                                                disabled={shop.isAvailable === false}
                                                            />
                                                            {isShopSelected && shop.description && (
                                                                <p
                                                                    className="u-text-sm u-mt-xxs-bis u-ml-md"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: shop.description,
                                                                    }}
                                                                />
                                                            )}
                                                            {shop.isAvailable === false && (
                                                                <p className="u-text-sm u-mt-xxs-bis u-ml-md">
                                                                    <FormattedMessage id="shipping.mode.disabled" />
                                                                </p>
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </li>
                                )}

                                {otherShippingModes.map(mode => {
                                    const isModeSelected = !!shippingMode && mode.uid === shippingMode.uid;
                                    const displayPrice = mode.price
                                        ? mode.displayPrice
                                        : intl.formatMessage({ id: 'shipping.free' });
                                    return (
                                        <li className="u-mb-xs-bis" key={mode.uid}>
                                            <Radio
                                                id={`shippingMode-${mode.uid}`}
                                                name="shippingMode"
                                                label={`${formatMode(mode.label as keyof Messages)} - ${displayPrice}`}
                                                onChange={() => {
                                                    onChangeMode(mode);
                                                }}
                                                checked={isModeSelected}
                                                disabled={mode.isAvailable === false}
                                            />

                                            {isModeSelected && mode.isAvailable && (
                                                <p
                                                    className="u-text-sm u-mt-xxs-bis u-ml-md"
                                                    dangerouslySetInnerHTML={{ __html: mode.description! }}
                                                />
                                            )}

                                            {!mode.isAvailable && (
                                                <p className="u-text-sm u-mt-xxs-bis u-ml-md">
                                                    {mode.unavailabilityReason ?? (
                                                        <FormattedMessage id="shipping.mode.disabled" />
                                                    )}
                                                </p>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShippingMode;
