import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import scrollTo from '../utils/scrollTo';
import GiftMessageForm from '../components/GiftMessageForm/GiftMessageForm';
import ErrorBoundary from '../components/Errors/ErrorBoundary';
import LoaderWrapper from '../components/Loader/LoaderWrapper';
import CommonRouteModals from '../components/Modal/CommonRouteModals';
import { FETCH_PROMO_QUERY_PARAMS } from '../components/Modal/PromosModal';
import Order from '../components/Order/Order';
import Promos from '../components/Promos/Promos';
import PushProducts from '../components/PushProducts/PushProducts';
import Total from '../components/Total/Total';
import Wishlist from '../components/Wishlist/Wishlist';
import { useAppContext } from '../context/AppContext';
import { useAuth } from '../context/AuthContext';
import { useOrderContext } from '../context/OrderContext';
import { useWorkflow } from '../context/WorkflowContext';
import { getRedirectUrl, useUrl } from '../hooks/useUrl';
import { OrderProduct } from '../types/api';
import useAnalytics from '../utils/analytics';
import { BRANDS } from '../utils/brand';
import { redirectToKeycloak } from '../utils/urls';

interface HistoryState {
    displayOptionsModal?: boolean;
    customisationProduct?: OrderProduct;
}

const titles = defineMessages({
    [BRANDS.sezane]: { id: `meta.title.cart.sezane` },
    [BRANDS.octobre]: { id: `meta.title.cart.octobre` },
});

const IndexRoute = () => {
    const { push } = useHistory<HistoryState>();
    const { authenticated, isAuthenticating } = useAuth();
    const { nextStep } = useWorkflow();
    const { isGlobalE, brand, site } = useAppContext();
    const intl = useIntl();
    const { order, isLoadingOrder } = useOrderContext();
    const { routes } = useUrl();
    const { onPageChange, onCheckoutCompleted } = useAnalytics();

    const [displayOptionsModal, setDisplayOptionsModal] = useState(false);
    const [customisationProduct, setCustomisationProduct] = useState<OrderProduct>();
    const [ecardMessageProduct, setEcardMessageProduct] = useState<OrderProduct>();
    const [editGiftMessage, setEditGiftMessage] = useState(true);

    const shouldDisplayOptionsModal = order?.packaging && order?.packaging?.length > 0;

    useEffect(() => {
        if (!isAuthenticating) {
            onPageChange(order);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticating]);

    const doNextStep = () => {
        onCheckoutCompleted(order!);

        if (authenticated) {
            nextStep();
            // Workaround to force refresh on router navigation to use globale
            if (isGlobalE) {
                push(routes.globalE);
                window.location.reload();
            } else {
                push(routes.shipping);
            }
        } else {
            const params = new URLSearchParams(`${FETCH_PROMO_QUERY_PARAMS}=true`);
            redirectToKeycloak(getRedirectUrl(site, routes.shipping, params));
        }
    };

    const submit = () => {
        if (shouldDisplayOptionsModal && !displayOptionsModal) {
            setDisplayOptionsModal(true);
        } else {
            doNextStep();
        }
    };

    return (
        <>
            <div className="o-container">
                <Helmet>
                    <title>{intl.formatMessage({ id: titles[brand].id })}</title>
                </Helmet>
                <LoaderWrapper
                    isLoading={isLoadingOrder || isAuthenticating}
                    loaderProps={{ large: true, fullPage: true }}
                >
                    {order && order?.products && order?.products?.length > 0 ? (
                        <div className="o-grid o-grid--gutter-sm-bis@sm o-grid--gutter-xxl@md-plus">
                            <div className="o-grid__col o-grid__col--22 o-grid__col--14@md-plus o-grid__col--offset-1">
                                <Promos className="u-none@md-plus" />
                                {order && (
                                    <div id="order">
                                        <ErrorBoundary>
                                            <Order
                                                order={order}
                                                setCustomisationProduct={setCustomisationProduct}
                                                setEcardMessageProduct={setEcardMessageProduct}
                                                setEditGiftMessage={() => {
                                                    setEditGiftMessage(true);
                                                    scrollTo('#gift-form');
                                                }}
                                            />
                                        </ErrorBoundary>
                                    </div>
                                )}
                                {order.enableGiftMessage && (!order.giftMessage || editGiftMessage) && (
                                    <GiftMessageForm
                                        defaultOpen={editGiftMessage}
                                        giftMessage={order.giftMessage}
                                        onConfirm={() => setEditGiftMessage(false)}
                                    />
                                )}
                            </div>
                            <div className="o-grid__col o-grid__col--22 o-grid__col--8@md-plus o-grid__col--offset-1@sm o-grid__col--offset-1@md">
                                <div className="c-page__sticky">
                                    <Promos className="u-none@sm" />
                                    <ErrorBoundary>
                                        <Total
                                            order={order}
                                            actionEl={
                                                <div className="u-mb-xs-sm">
                                                    <button className="c-btn u-w-100" onClick={submit}>
                                                        <FormattedMessage id="actions.order" />
                                                    </button>
                                                    <button
                                                        className="c-btn c-btn--order-lg c-btn--fixed@sm c-btn--fixed@md u-w-100 u-none@md-plus"
                                                        onClick={submit}
                                                    >
                                                        <FormattedMessage id="actions.order" />
                                                    </button>
                                                </div>
                                            }
                                            setDisplayOptionsModal={setDisplayOptionsModal}
                                            setCustomisationProduct={setCustomisationProduct}
                                            setEcardMessageProduct={setEcardMessageProduct}
                                        />
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <PushProducts />
                                    </ErrorBoundary>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="o-grid">
                            <div className="o-grid__col o-grid__col--22 o-grid__col--offset-1 u-mt-lg@sm u-mb-lg@sm u-mt-xl u-mb-xl">
                                <p className="u-h3 u-text-normal u-text-primary u-text-center">
                                    <FormattedMessage id="cart.empty" />
                                </p>
                            </div>
                        </div>
                    )}
                </LoaderWrapper>
            </div>
            {authenticated && order && (
                <ErrorBoundary>
                    <Wishlist />
                </ErrorBoundary>
            )}

            <CommonRouteModals
                order={order}
                onConfirmDisplayOptions={doNextStep}
                displayOptionsModal={displayOptionsModal}
                setDisplayOptionsModal={setDisplayOptionsModal}
                customisationProduct={customisationProduct}
                setCustomisationProduct={setCustomisationProduct}
                ecardMessageProduct={ecardMessageProduct}
                setEcardMessageProduct={setEcardMessageProduct}
            />
        </>
    );
};

export default IndexRoute;
