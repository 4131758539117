import { initDatadog as initDatadogComponents } from '@sezane/front-components';

export const initDatadog = () => {
    if (process.env.NODE_ENV !== 'production') {
        return;
    }

    initDatadogComponents({
        clientToken: 'pub0bcac84d7d706c4b8eb0b2164b145ad3',
        applicationId: '3113b3f6-156e-4739-b51e-668dba37652e',
        site: 'datadoghq.eu',
        service: 'checkout',
        env: process.env.REACT_APP_ENV,
        version: process.env.COMMIT_HASH,
        sessionSampleRate: 50,
        sessionReplaySampleRate: 5,
        traceSampleRate: 100,
        traceContextInjection: 'all',
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        trackSessionAcrossSubdomains: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: ['https://api.sezane.com', 'https://api.octobre-editions.com'],
    });
};

export function addError(error: Error) {
    if (window.DD_RUM && window.DD_RUM.addError) {
        window.DD_RUM.addError(error);
    }
}

export function addAction(action: string, context?: Record<string, any>) {
    if (window.DD_RUM && window.DD_RUM.addAction) {
        window.DD_RUM.addAction(action, context);
    }
}
