import { ErrorMessage } from '@hookform/error-message';
import { FieldErrors } from 'react-hook-form';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

type Props = {
    name: string;
    errors: FieldErrors;
};

const FieldErrorMessage = ({ name, errors }: Props) => {
    return (
        <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) =>
                message ? (
                    <p className="c-form-group__validation-message">
                        <FormattedMessage id={message as MessageDescriptor['id']} />
                    </p>
                ) : null
            }
        />
    );
};

export default FieldErrorMessage;
