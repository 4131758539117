import { GlobalECountryData } from '@sezane/front-components';
import classNames from 'classnames';
import { FormattedMessage, MessageDescriptor, defineMessages } from 'react-intl';

import { useAppContext } from '../../context/AppContext';

const chargesTranslations = defineMessages({
    AU: { id: 'cart.total.au_charges' },
    US: { id: 'cart.total.us_charges' },
});

const chargesBelowTranslations = defineMessages<string>({
    au: { id: 'cart.total.charges_below.au' },
    ca: { id: 'cart.total.charges_below.ca' },
    de: { id: 'cart.total.charges_below.de' },
    en: { id: 'cart.total.charges_below.en' },
    es: { id: 'cart.total.charges_below.es' },
    eu: { id: 'cart.total.charges_below.eu' },
    fr: { id: 'cart.total.charges_below.fr' },
    it: { id: 'cart.total.charges_below.it' },
    nl: { id: 'cart.total.charges_below.nl' },
    pt: { id: 'cart.total.charges_below.pt' },
    us: { id: 'cart.total.charges_below.us' },
});

export const COUNTRY_CHARGES: Record<string, { amount: number; label: MessageDescriptor['id'] }> = {
    AU: {
        amount: 100000,
        label: chargesTranslations.AU.id,
    },
    US: {
        amount: 80000,
        label: chargesTranslations.US.id,
    },
};

interface ChargesProps {
    className?: string;
    element?: keyof JSX.IntrinsicElements;
    country: GlobalECountryData;
    subTotalPrice?: number;
}

const Charges = ({ className, country, element, subTotalPrice }: ChargesProps) => {
    const { site } = useAppContext();
    const chargeInfo = COUNTRY_CHARGES[country.countryISO!];

    if (!chargeInfo || subTotalPrice === undefined) return null;

    const Tag = element || 'p';
    const isAbove = subTotalPrice >= chargeInfo.amount;

    return (
        <Tag className={classNames(className)} style={{ color: isAbove ? undefined : 'var(--text-color)' }}>
            {isAbove ? (
                <FormattedMessage id={chargeInfo.label} />
            ) : site.code ? (
                <FormattedMessage id={chargesBelowTranslations[site.code].id} />
            ) : null}
        </Tag>
    );
};

export default Charges;
