import { FormattedMessage } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { useAppContext } from '../../context/AppContext';
import { useAuth } from '../../context/AuthContext';
import { useOrderContext } from '../../context/OrderContext';
import useCurrency from '../../hooks/useCurrency';
import useMutationWithError from '../../hooks/useMutationWithError';
import { UserCredit } from '../../types/api';
import apiClient from '../../utils/api';
import Dropdown from '../Dropdown/Dropdown';
import Loader from '../Loader/Loader';
import { FETCH_ORDER_KEY } from '../../utils/order';

const UserCreditsDropdown = () => {
    const queryClient = useQueryClient();
    const { authenticated, keycloakId } = useAuth();
    const { brand, orderId, site } = useAppContext();
    const { order } = useOrderContext();
    const currency = useCurrency();

    const { data: userCreditsResponse, isLoading: loadingUserCredits } = useQuery(
        'userCredits',
        () => apiClient.getUserCredits(keycloakId, { brand, site: site.code! }),
        {
            enabled: authenticated,
        }
    );

    const addCredit = useMutationWithError(
        (voucherCode: string) =>
            apiClient.addCredit(
                orderId,
                {
                    site: site.code!,
                    brand,
                },
                { voucherCode }
            ),
        {
            onSuccess: data => {
                if (data) {
                    queryClient.setQueryData(FETCH_ORDER_KEY, data);
                }
            },
        }
    );

    const items =
        userCreditsResponse?.data.filter(
            item =>
                item.remainingAmount! > 0 &&
                new Date(item.expiredAt!).getTime() > Date.now() &&
                !item.usedAt &&
                !(order?.userCredits && order?.userCredits.find(userCredit => item.id === userCredit.id)) &&
                item.currencyCode?.toLowerCase() === currency?.code?.toLowerCase()
        ) || [];

    if (loadingUserCredits) return <Loader className="u-mb-xs-sm" />;

    if (!items?.length) return null;

    return (
        <Dropdown
            className="u-mb-xs-sm u-w-100"
            defaultLabel={
                items.length ? (
                    <FormattedMessage id="cart.total.available_credits" />
                ) : (
                    <FormattedMessage id="cart.total.no_credits" />
                )
            }
            items={items}
            onChange={(userCredit: UserCredit) => addCredit.mutate(userCredit.code!)}
            renderItem={(userCredit: UserCredit) => `${userCredit.displayPrice} - ${userCredit.code}`}
            disabled={!items.length}
        />
    );
};

export default UserCreditsDropdown;
