import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';
import { ErrorInfo, ReactNode } from 'react';

const onError = (error: Error, info: ErrorInfo) => {
    if (window.DD_RUM && window.DD_RUM.addError) {
        window.DD_RUM.addError(error, info.componentStack);
    }
};

const ErrorBoundary = ({ children }: { children: ReactNode }) => {
    return (
        <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
            {children ? children : null}
        </ReactErrorBoundary>
    );
};

export default ErrorBoundary;
