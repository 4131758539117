import { useIntl } from 'react-intl';

import { getValidSiteCodeAndLocaleFromUrl, GlobalECountry } from '@sezane/front-components';
import { useAppContext } from '../context/AppContext';
import { Site } from '../types/api';
import { BRANDS, getAccountUrl } from '../utils/brand';
import { DEFAULT_SITECODE } from '../utils/common';

export const getRedirectUrl = (site: Site, route: string, queryParams?: URLSearchParams, locale?: string) =>
    `${window.location.protocol}//${window.location.host}/${site.code}${locale ? `-${locale}` : ''}${
        process.env.REACT_APP_BASE_PATH || ''
    }${route}${queryParams ? '?' + queryParams.toString() : ''}`;

export type Routes = {
    cart: string;
    shipping: string;
    payment: string;
    globalE: string;
    paymentSuccess: string;
};

export const getRoutes = (siteCode = '', locale?: string): Routes => {
    switch (siteCode) {
        case 'fr':
            return {
                cart: '/panier',
                shipping: '/livraison',
                payment: '/paiement',
                globalE: '/checkout-global-e',
                paymentSuccess: '/paiement-confirmation',
            };
        case 'es':
            return {
                cart: '/cesta',
                shipping: '/envio',
                payment: '/pago',
                globalE: '/checkout-global-e',
                paymentSuccess: '/pago-confirmacion',
            };
        case 'de':
            return {
                cart: '/warenkorb',
                shipping: '/lieferung',
                payment: '/zahlung',
                globalE: '/checkout-global-e',
                paymentSuccess: '/zahlungsbestatigung',
            };
        case 'it':
            return {
                cart: '/carrello',
                shipping: '/consegna',
                payment: '/pagamento',
                globalE: '/checkout-global-e',
                paymentSuccess: '/pagamento-conferma',
            };
        case 'pt':
            return {
                cart: '/cartao',
                shipping: '/envio',
                payment: '/pagamento',
                globalE: '/checkout-global-e',
                paymentSuccess: '/pagamento-confirmacao',
            };
        case 'nl':
            return {
                cart: '/winkelmandje',
                shipping: '/levering',
                payment: '/betaling',
                globalE: '/checkout-global-e',
                paymentSuccess: '/betalingsbevestiging',
            };

        case 'eu':
            return getRoutes(locale);
        default:
            return {
                cart: '/cart',
                shipping: '/shipping',
                payment: '/payment',
                globalE: '/checkout-global-e',
                paymentSuccess: '/payment-success',
            };
    }
};

export const getCgvPath = (brand: BRANDS, siteCode = '', siteSegment: string, locale: string): string => {
    if (brand === BRANDS.sezane) {
        switch (siteCode) {
            case 'fr':
                return 'conditions-generales-de-vente';
            case 'es':
                return 'condiciones-generales-de-venta';
            case 'eu':
                return getCgvPath(brand, locale, siteSegment, locale);
            case 'it':
                return 'condizioni-generali-di-vendita';
            case 'nl':
                return 'algemene-verkoopvoorwaarden';
            case 'pt':
                return 'condicoes-gerais-de-venda';
            default:
                return 'terms-conditions';
        }
    } else {
        switch (siteCode) {
            case 'fr':
                return 'a-propos/conditions-generales-de-vente';
            case 'eu':
                return getCgvPath(brand, locale, siteSegment, locale);
            default:
                return 'about/terms-conditions';
        }
    }
};

export const getPrivacyPolicyPath = (brand: BRANDS, siteCode = '', siteSegment: string, locale: string): string => {
    if (brand === BRANDS.sezane) {
        switch (siteCode) {
            case 'fr':
                return 'politique-de-confidentialite-des-donnees';
            case 'es':
                return 'politica-de-confidencialidad-de-datos';
            case 'eu':
                return getCgvPath(brand, locale, siteSegment, locale);
            case 'it':
                return 'informativa-sulla-privacy-per-il-trattamento-dei-dati-personali';
            case 'nl':
                return 'privacybeleid';
            case 'pt':
                return 'politica-de-confidencialidade-dos-dados';
            default:
                return 'privacy-policy';
        }
    } else {
        switch (siteCode) {
            case 'fr':
                return 'a-propos/politique-de-confidentialite-des-donnees';
            case 'eu':
                return getCgvPath(brand, locale, siteSegment, locale);
            default:
                return 'about/privacy-policy';
        }
    }
};

export const getAccountPath = (siteCode = '', locale?: string): string => {
    switch (siteCode) {
        case 'fr':
            return 'mon-compte/mon-compte';
        case 'de':
            return 'mein-konto/mein-konto';
        case 'es':
            return 'mi-cuenta/mi-cuenta';
        case 'it':
            return 'il-mio-account/il-mio-account';
        case 'pt':
            return 'minha-conta/minha-conta';
        case 'nl':
            return 'mijn-account/mijn-account';
        case 'eu':
            return getAccountPath(locale);
        default:
            return 'my-account/my-account';
    }
};

export const getWishlistPath = (siteCode = '', locale?: string): string => {
    switch (siteCode) {
        case 'fr':
            return 'wishlists';
        case 'de':
            return 'wunschlisten';
        case 'es':
        case 'pt':
            return 'listas-de-deseos';
        case 'it':
            return 'liste-desideri';
        case 'nl':
            return 'mijn-account/mijn-meldingen';
        case 'eu':
            return getWishlistPath(locale);
        default:
            return 'wishlists';
    }
};

export const getCountryRootUrl = (country: GlobalECountry, mappingCountrySite: Record<string, string>) => {
    const siteCode = mappingCountrySite[country.countryISO || ''] || 'fr';
    const routes = getRoutes(siteCode);
    return `/${siteCode}${process.env.REACT_APP_BASE_PATH || ''}${routes.cart}`;
};

export const getSiteUrlSegment = (site: Site, pathname = window.location.pathname, isGlobalE = false) => {
    const { siteCode, localeCode } = getValidSiteCodeAndLocaleFromUrl(site, pathname);

    if (isGlobalE) {
        return `${site.code || siteCode}`;
    }

    return `${site.code || siteCode}-${localeCode}`;
};

export const useUrl = () => {
    const { frontUrl, site, brand, isGlobalE } = useAppContext();
    const { locale } = useIntl();
    const siteSegment = getSiteUrlSegment(site, window.location.pathname, isGlobalE);
    const routes = getRoutes(site.code);
    const ecommerceUrl = `${frontUrl}/${siteSegment}`;
    const checkoutBaseUrl = `/${siteSegment}`;

    return {
        urls: {
            ecommerce: ecommerceUrl,
            ecommerceRoot: frontUrl,
            checkout: `${checkoutBaseUrl}${process.env.REACT_APP_BASE_PATH || ''}`,
            account: getAccountUrl(),
            logout: `${ecommerceUrl}/disconnect`,
            wishlist: `${getAccountUrl()}/${getWishlistPath(locale)}`,
            cgv: `${ecommerceUrl}/${getCgvPath(brand, site.code, siteSegment, locale)}`,
            cgvJp: `${frontUrl}/${site.code}/act-on-specified-commercial-transactions`,
            privacyPolicy: `${ecommerceUrl}/${getPrivacyPolicyPath(brand, site.code, siteSegment, locale)}`,
        },
        routes,
    };
};
