import React, { ReactNode, useContext } from 'react';
import { UseMutationResult, useQuery, useQueryClient } from 'react-query';
import useCurrency from '../hooks/useCurrency';
import useMutationWithError, { MutationError } from '../hooks/useMutationWithError';
import { HttpResponse, Order } from '../types/api';
import apiClient from '../utils/api';
import { FETCH_ORDER_KEY, resetOrderId, updateExpireAtCartCookie } from '../utils/order';
import { useAppContext } from './AppContext';
import { useAuth } from './AuthContext';
import { useCountry } from './CountryContext';
import { useIntl } from 'react-intl';

type UpdateOrderParams = {
    site: string | undefined;
    currencyCode: string | undefined;
    country: string | undefined;
};

type UpdateOrderMutation = UseMutationResult<
    HttpResponse<Order, void>,
    MutationError,
    UpdateOrderParams | undefined,
    unknown
>;

type OrderContextType = {
    order?: Order;
    isLoadingOrder: boolean;
    updateOrder: UpdateOrderMutation;
};

const OrderContext = React.createContext<OrderContextType>({
    isLoadingOrder: false,
    updateOrder: {} as UpdateOrderMutation,
});

export const OrderContextProvider = ({ children }: { children: ReactNode }) => {
    const { isAuthenticating } = useAuth();
    const { site, brand, orderId } = useAppContext();
    const queryClient = useQueryClient();
    const currency = useCurrency();
    const { country } = useCountry();
    const intl = useIntl();

    const { data: orderResponse, isLoading: isLoadingOrder } = useQuery(
        FETCH_ORDER_KEY,
        () =>
            apiClient.getOrder(orderId, {
                site: site?.code!,
                brand,
                locale: intl.locale,
            }),
        {
            refetchOnWindowFocus: true,
            enabled: !!orderId && !!site && !isAuthenticating,
            onSuccess: data => {
                // In some async/race cases, we got null here instead of a valid response. Not sure why
                if (data && data.data) {
                    updateExpireAtCartCookie(orderId, data.data.expireAt!);
                }
            },
            onError: (error: any) => {
                if (error && error.status === 404) {
                    resetOrderId();
                }
            },
        }
    );

    const updateOrder = useMutationWithError(
        (override?: UpdateOrderParams) => {
            return apiClient.updateOrder(
                orderId,
                {
                    site: site?.code!,
                    brand,
                },
                override || {
                    site: site?.code!,
                    currencyCode: currency?.code,
                    country: country.countryISO,
                }
            );
        },
        {
            onSuccess: data => {
                if (data) {
                    queryClient.setQueryData(FETCH_ORDER_KEY, data);
                }
            },
        }
    );

    return (
        <OrderContext.Provider
            value={{
                isLoadingOrder,
                order: orderResponse?.data,
                updateOrder,
            }}
        >
            {children}
        </OrderContext.Provider>
    );
};

export const useOrderContext = () => {
    return useContext(OrderContext);
};

export default OrderContextProvider;
