import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useAppContext } from '../../context/AppContext';
import useMutationWithError from '../../hooks/useMutationWithError';
import { OrderProduct, PackagingOption } from '../../types/api';
import apiClient from '../../utils/api';
import Button from '../Buttons/Button';
import MultiSelect from '../MultiSelect/MultiSelect';
import Modal from './Modal';
import { FETCH_ORDER_KEY } from '../../utils/order';

type OptionsModalProps = {
    onConfirm?: () => void;
    onRequestClose: () => void;
    products?: OrderProduct[];
};

// We need to override the legacy behavior to match the futur OMS behavior
const formatPackagingOptions = (packagingOptions: PackagingOption[]) => {
    const isSelectedAll = packagingOptions.find(option => option.packagingReference?.includes('-all-'))?.selected;
    const options = packagingOptions.filter(option => !option.packagingReference?.includes('-all-'));

    if (isSelectedAll) {
        return options.map(option => {
            return { ...option, selected: true };
        });
    }

    return options;
};

const OptionsModal = ({ onConfirm, onRequestClose, products = [] }: OptionsModalProps) => {
    const queryClient = useQueryClient();
    const { brand, orderId, site } = useAppContext();
    const [packagingOptions, setPackagingOptions] = useState<Record<number, PackagingOption[]>>(
        // @ts-ignore
        products.reduce(
            (obj, item, index) => ({
                ...obj,
                [index]: item.packagingOptions
                    ? formatPackagingOptions(item.packagingOptions)?.filter(option => option.selected)
                    : [],
            }),
            {}
        )
    );

    const updatePackaging = useMutationWithError(
        () =>
            apiClient.updatePackagingOptions(
                orderId,
                {
                    site: site.code!,
                    brand,
                },
                {
                    options: Object.values(packagingOptions)
                        .flat()
                        .map(option => option.packagingReference!),
                }
            ),
        {
            onSuccess: data => {
                if (data) {
                    queryClient.setQueryData(FETCH_ORDER_KEY, data);
                }

                onRequestClose();
            },
        }
    );

    return (
        <Modal onRequestClose={onRequestClose} isOpen className="c-dialog--lg c-dialog--box-sm@sm">
            <h4 className="u-h4-bis u-text-uppercase">
                <FormattedMessage id="options.title" />
            </h4>
            <p className="u-mt-xs-sm u-mt-sm@md u-mt-sm@md-plus">
                <FormattedMessage id="options.subtitle" />
            </p>

            <div className="c-order-checkout c-order-checkout--options u-mt-sm">
                <div className="c-order-checkout__body">
                    {products.map(
                        (product, index) =>
                            product.packagingOptions &&
                            product.packagingOptions.length > 0 && (
                                <div className="c-order-checkout__item" key={index}>
                                    <div className="o-grid o-grid--gutter-sm-bis@sm">
                                        <div className="o-grid__col o-grid__col--10 o-grid__col--5@md o-grid__col--5@md-plus">
                                            <div className="c-order-checkout__image">
                                                <img
                                                    src={product.imageUrl}
                                                    alt={product.label}
                                                    width="122"
                                                    height="178"
                                                    className="u-w-100"
                                                />
                                            </div>
                                        </div>
                                        <div className="o-grid__col o-grid__col--14 o-grid__col--19@md o-grid__col--19@md-plus">
                                            <p className="c-order-checkout__label">{product.label}</p>
                                            <p className="u-mt-n">
                                                <FormattedMessage id="cart.list.color" /> {product.colorLabel}
                                                {' - '}
                                                <FormattedMessage id="cart.list.size" /> {product.sizeLabel}
                                            </p>
                                            <MultiSelect
                                                items={formatPackagingOptions(product.packagingOptions)}
                                                name={`packaging-${index}`}
                                                itemKey="packagingReference"
                                                onChange={options =>
                                                    setPackagingOptions(state => {
                                                        return { ...state, [index]: options };
                                                    })
                                                }
                                                renderItem={item => item.title}
                                                selectedValues={formatPackagingOptions(packagingOptions[index])}
                                                selectAllLabel={<FormattedMessage id="packaging.option.all" />}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                    )}
                </div>
            </div>

            <div className="u-text-center u-mt-sm">
                <Button
                    type="button"
                    className="c-btn c-btn--loader u-w-100"
                    onClick={() => {
                        updatePackaging.mutateAsync().then(() => {
                            onConfirm && onConfirm();
                            onRequestClose();
                        });
                    }}
                    isLoading={updatePackaging.isLoading}
                >
                    <FormattedMessage id="actions.order" />
                </Button>
                <Button
                    type="button"
                    className="c-btn c-btn--secondary u-w-100 u-mt-sm"
                    onClick={() => {
                        onConfirm && onConfirm();
                        onRequestClose();
                    }}
                >
                    <FormattedMessage id="options.ignore_step" />
                </Button>
            </div>
        </Modal>
    );
};

export default OptionsModal;
