import { ReactNode, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useQuery } from 'react-query';

import LoaderWrapper from '../components/Loader/LoaderWrapper';
import { IntlLocales, Messages } from '../types';
import { useAppContext } from './AppContext';

type TranslationProviderProps = {
    children?: ReactNode;
    initialTranslations?: Messages;
};

const TranslationProvider = ({ children, initialTranslations }: TranslationProviderProps) => {
    const { site, locale } = useAppContext();
    const [translations, setTranslations] = useState(initialTranslations);

    const importTranslation = async () => {
        const localeTranslation = await import(`../lang/${locale}.json`);
        return localeTranslation.default;
    };

    useQuery(['fetchTranslations'], () => importTranslation(), {
        onSuccess: data => setTranslations(data),
        enabled: !initialTranslations && !!locale,
    });

    return (
        <LoaderWrapper isLoading={!site?.id || !translations} loaderProps={{ fullScreen: true, large: true }}>
            <IntlProvider locale={locale as IntlLocales} messages={translations}>
                {children}
            </IntlProvider>
        </LoaderWrapper>
    );
};

export default TranslationProvider;
