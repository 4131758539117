import React from 'react';
import { FormValues } from './types/Customisation';

enum Position {
    HEART = 'heart',
    RIGHT = 'right',
}

type Props = FormValues & {
    symbols?: string[];
};

const formatCharacters = (characters: string, symbols?: string[]) => {
    let value = [];

    for (let i = 0; i < characters.length; i += 1) {
        if (symbols && symbols.includes(characters[i])) {
            value.push(
                <span key={i} className="c-preview-customisation__symbol">
                    {characters[i]}
                </span>
            );
        } else {
            value.push(<React.Fragment key={i}>{characters[i]}</React.Fragment>);
        }
    }

    return value;
};

const Preview = ({ rows, color, position, symbols }: Props) => {
    return position ? (
        <p
            className="c-preview-customisation__text"
            style={{
                color: color || undefined,
                ...(position === Position.HEART
                    ? {
                          top: '36%',
                          left: '55%',
                          right: 'auto',
                      }
                    : {
                          top: '36%',
                          left: 'auto',
                          right: '55%',
                      }),
            }}
        >
            {rows?.map((row, index) =>
                row ? (
                    <React.Fragment key={index}>
                        {formatCharacters(row, symbols)}
                        <br />
                    </React.Fragment>
                ) : null
            )}
        </p>
    ) : null;
};

export default Preview;
