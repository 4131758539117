import { defineMessages } from 'react-intl';
import * as yup from 'yup';
import type { UserAddress, RelayPoint, Address } from '../types/api';
import { GlobalECountry, WHITESPACE_REGEX } from '@sezane/front-components';
import { AddressForm } from '../components/Address/AddressInputsTypes';
import type { User } from '../types/apiOms';

interface SetUserAddressEmptyValues {
    user?: User;
    country?: GlobalECountry;
    countryCode?: string;
}
export const FETCH_USER_ADDRESSES_KEY = 'userAddresses';

export const NEW_ADDRESS_ID = 0;

export const setUserAddressEmptyValues = ({ user, country, countryCode }: SetUserAddressEmptyValues): UserAddress => ({
    id: NEW_ADDRESS_ID,
    address: '',
    addressMore: '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    country: country?.label,
    countryCode,
    city: '',
    company: '',
    isDefaultBilling: false,
    isDefaultShipping: false,
    name: '',
    //@ts-expect-error => Use OMS logic inside form address
    phone: user?.phoneNumber,
    postcode: '',
    relayId: undefined,
    inputType: undefined,
});

const validationMessages = defineMessages({
    idRequired: { id: 'validation.addressRequired.required' },
    phoneRequired: { id: 'validation.address.phone.required' },
    phoneValid: { id: 'validation.address.phone.invalid' },
    postcodeRequired: { id: 'validation.address.postcode.required' },
    cityRequired: { id: 'validation.address.city.required' },
    cityInvalid: { id: 'validation.address.city.invalid' },
    addressRequired: { id: 'validation.address.address.required' },
    latinCharacters: { id: 'validation.address.address.latin_characters' },
    firstNameRequired: { id: 'validation.address.address.firstName.required' },
    lastNameRequired: { id: 'validation.address.address.lastName.required' },
    nameRequired: { id: 'validation.address.address.name.required' },
    poBox: { id: 'validation.address.address.address.po_box' },
    postcodeInvalidShipping: { id: 'validation.address.postcode.invalid_shipping' },
    charactersRemaining: { id: 'validation.characters_remaining' },
});

export const relayValidation = yup.object<Partial<Record<keyof RelayPoint['relayAddress'], yup.AnySchema>>>({
    firstName: yup
        .string()
        .required(validationMessages.firstNameRequired.id)
        .test('whitespace', validationMessages.firstNameRequired.id!, value => !WHITESPACE_REGEX.test(value!)),
    lastName: yup
        .string()
        .required(validationMessages.lastNameRequired.id)
        .test('whitespace', validationMessages.lastNameRequired.id!, value => !WHITESPACE_REGEX.test(value!)),
});

export function formatAdressForApi(address: AddressForm) {
    if (address.streetNumber && address?.streetNumber !== '' && address.streetNumberPosition) {
        if (address.streetNumberPosition === 'before') {
            address.address = `${address.streetNumber} ${address.address}`;
        } else {
            address.address = `${address.address} ${address.streetNumber}`;
        }
    }

    address.firstName = address.firstName?.trim();
    address.lastName = address.lastName?.trim();
    address.name = address.name?.trim();
    // @ts-expect-error
    address.phoneNumber = address.phone;

    return address;
}

export function isSameAddressCMSApi(
    address1: Address | UserAddress | AddressForm,
    address2: Address | UserAddress | AddressForm
): boolean {
    return (
        address1.name === address2.name &&
        address1.firstName === address2.firstName &&
        address1.lastName === address2.lastName &&
        address1.address === address2.address &&
        address1.city === address2.city &&
        address1.postcode === address2.postcode &&
        (address1.countryCode === address2.countryCode ||
            address1.countryCode === `${address2.countryCode}-${(address2 as Address).state}` ||
            `${address1.countryCode}-${(address1 as Address).state}` === address2.countryCode) &&
        // @ts-expect-error
        address1.phone === address2.phone &&
        address1.address !== undefined &&
        address2.address !== undefined
    );
}

export const formatAddressFromCMStoOMS = (address: UserAddress): Address => {
    const { phoneNumber, ...formattedBillingAddress } = address;
    return { ...formattedBillingAddress, phone: phoneNumber || '' } as Address;
};

export function isNewAddress(address?: UserAddress) {
    // Kind of a hack to handle new address choice
    return address && address.id === NEW_ADDRESS_ID;
}
