import { getValidSiteCodeAndLocaleFromUrl, isGlobalECountry } from '@sezane/front-components';
import Cookies from 'js-cookie';
import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useFocus } from '../hooks/useFocus';
import { Site, SitesList } from '../types/api';
import apiClient from '../utils/api';
import { BRANDS, getFrontUrl } from '../utils/brand';
import { FETCH_ORDER_KEY, getOrderId, resetOrderId as resetOrderIdCookie } from '../utils/order';

const DEVISE_COOKIE_NAME = 'Sezane';
const DEFAULT_DEVISE = 1;

type AppContextType = {
    brand: BRANDS;
    countries: SitesList['countries'];
    frontUrl: string;
    isGlobalE: boolean;
    site: Site;
    locale?: string;
    sites: Site[];
    orderId: string;
    mappingCountrySite: Record<string, string>;
    resetOrderId: () => void;
};

type InitialData = Pick<AppContextType, 'countries' | 'orderId' | 'site' | 'sites' | 'mappingCountrySite'>;

type AppProviderProps = {
    children: ReactNode;
    brand: BRANDS;
    initialData?: InitialData;
};

const AppContext = React.createContext<AppContextType>({
    brand: BRANDS.sezane,
    countries: [],
    frontUrl: '',
    isGlobalE: false,
    orderId: '',
    site: {},
    locale: '',
    sites: [],
    mappingCountrySite: {},
    resetOrderId: () => {},
});

export const getSite = (sites?: Site[]) => {
    const deviseCookie = Cookies.get(DEVISE_COOKIE_NAME);

    let site: Site | undefined;

    if (sites) {
        // if sites loaded
        if (deviseCookie) {
            // if we have a cookie, get site value from there
            const devise = Number(deviseCookie.replace('SiteDevise=', ''));
            site = sites.find(s => s.legacyCurrencyCookie === (isNaN(devise) ? DEFAULT_DEVISE : devise));
        } else {
            // if we don't, guess the site value from the url
            site = sites.find(s => window.location.pathname.startsWith(`/${s.code}`));

            if (!site) {
                // if we still haven't found the site, we default to FR
                site = sites.find(s => s.code === 'fr');
            }
        }
    }

    return site;
};

export const AppContextProvider = ({ brand, children, initialData }: AppProviderProps) => {
    const cookieOrderId = getOrderId();

    const queryClient = useQueryClient();
    const { data } = useQuery('sites', () => apiClient.getBrandWebsites(brand, undefined, { secure: false }), {
        enabled: !initialData,
    });

    const [orderId, setOrderId] = useState<string>(initialData ? initialData.orderId : cookieOrderId);
    const countries = initialData ? initialData.countries : data?.data.countries;
    const sites = initialData ? initialData.sites : data?.data.sites;
    const site = initialData ? initialData.site : getSite(sites);
    const mappingCountrySite = initialData ? initialData.mappingCountrySite : data?.data.mappingCountrySite || {};
    const isGlobalE =
        site?.code === 'eu' && data?.data.globalECountries ? isGlobalECountry(data?.data.globalECountries) : false;
    const locale = site ? getValidSiteCodeAndLocaleFromUrl(site).localeCode : undefined;

    const setOrderIdState = useCallback(
        (newOrderId: string) => {
            if (orderId !== '' && newOrderId === '') {
                queryClient.setQueryData(FETCH_ORDER_KEY, null);
            }

            setOrderId(newOrderId);
        },
        [orderId, queryClient]
    );

    const resetOrderId = useCallback(() => {
        resetOrderIdCookie();
        setOrderIdState(getOrderId());
    }, []);

    useFocus(() => {
        setOrderIdState(getOrderId());
    });

    useEffect(() => {
        if (cookieOrderId && cookieOrderId !== orderId) {
            setOrderIdState(cookieOrderId);
        }
    }, [cookieOrderId, orderId, setOrderIdState]);

    return (
        <AppContext.Provider
            value={{
                brand,
                countries,
                frontUrl: getFrontUrl(),
                isGlobalE,
                site: site || {},
                locale,
                sites: sites || [],
                orderId,
                resetOrderId,
                mappingCountrySite: mappingCountrySite as Record<string, string>,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    const siteContext = useContext(AppContext);
    return siteContext;
};
