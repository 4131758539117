import { PaymentMethod } from '@adyen/adyen-web/dist/types/types';
import Cookies from 'js-cookie';
import { AdyenPaymentResponse, Order, OrderProduct } from '../types/api';

const CART_UPDATED_COOKIE_NAME = 'SezUpdated';
const CART_COOKIE_NAME = 'SezaneCartUuid';
const ORDER_CACHE_KEY = 'checkoutOrder';
const PAYMENT_CACHE_KEY = 'checkoutPayment';
export const FETCH_ORDER_KEY = 'order';

/**
 * Get domain according to sezane envs
 *
 * @example
 * // returns .qlf.sezane.com
 * getDomain('www.qlf.sezane.com')
 * @example
 * // returns .sezane.com
 * getDomain('shop.sezane.com')
 */
export const getDomain = (host: string = window.location.host) =>
    host.replace('shop', '').replace('www-nocache', '').replace('www', '');

export const getOrderId = () => {
    return Cookies.get(CART_COOKIE_NAME) || '';
};

export const resetOrderId = () => {
    const domain = getDomain(); // eg: sezane.com

    Cookies.remove(CART_COOKIE_NAME, { path: '/', domain });
};

export const hasQuickDeliveryProducts = (orderProduct: Order | OrderProduct) =>
    orderProduct.lastMinuteQuantity !== undefined &&
    orderProduct.lastMinuteQuantity > 0 &&
    !(orderProduct as OrderProduct).gift;
export const hasNormalDeliveryProducts = (orderProduct: Order | OrderProduct) =>
    orderProduct.simpleDeliveryQuantity !== undefined &&
    orderProduct.simpleDeliveryQuantity > 0 &&
    !(orderProduct as OrderProduct).gift;
export const isMixedOrder = (order: Order | OrderProduct) =>
    hasQuickDeliveryProducts(order) && hasNormalDeliveryProducts(order);

export const setCartUpdated = () =>
    Cookies.set(CART_UPDATED_COOKIE_NAME, Math.floor(Date.now() / 1000).toString(), {
        path: '/',
        domain: getDomain(),
        secure: true,
        sameSite: 'Lax',
    });

export const updateExpireAtCartCookie = (orderId: string, expireAt: string) => {
    Cookies.set(CART_COOKIE_NAME, orderId, {
        path: '/',
        domain: getDomain(),
        expires: new Date(expireAt),
        sameSite: 'None',
        secure: true,
    });
};

export const redirectResult = new URLSearchParams(window.location.search).get('redirectResult');

export type LastOrderData = {
    order: Order;
    paymentMethod: PaymentMethod;
};

// This cache is only used to send analytics after payment since the order doesn't exist anymore
export const saveLastOrderData = (order: Order, paymentMethod: PaymentMethod) => {
    localStorage.setItem(ORDER_CACHE_KEY, JSON.stringify({ order, paymentMethod }));
};

export const getLastOrderData = (): LastOrderData | null => {
    const order = localStorage.getItem(ORDER_CACHE_KEY);
    return order ? JSON.parse(order) : null;
};

export const resetPaymentData = () => {
    localStorage.removeItem(PAYMENT_CACHE_KEY);
};

export const savePaymentData = (paymentData: AdyenPaymentResponse) => {
    localStorage.setItem(PAYMENT_CACHE_KEY, JSON.stringify(paymentData));
};

export const getPaymentData = (): AdyenPaymentResponse | null => {
    const paymentData = localStorage.getItem(PAYMENT_CACHE_KEY);
    return paymentData ? JSON.parse(paymentData) : null;
};
